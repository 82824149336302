import Rating from '@mui/material/Rating';
import {styled} from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import React, {useEffect, useState} from "react";
import DashboardTab from '../../components/DashboardTab.jsx';
import axios from "axios";
import moment from "moment";
import {Backdrop, CircularProgress} from "@mui/material";

const CustomRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#F96300',
    },
    '& .MuiRating-iconEmpty': {
        color: '#F96300',
        opacity: 0.5,
    },
});

const CustomProgressBar = styled(LinearProgress)({
    height: 10,
    borderRadius: 5,
    backgroundColor: '#F3F7F8',
    '& .MuiLinearProgress-bar': {
        backgroundColor: '#00414A',
    },
});

const Reviews = () => {
    const beachId = sessionStorage.getItem('beach_id');
    const [selectedTab, setSelectedTab] = useState('all');
    const [isLoading, setIsLoading] = useState(false);

    const [reviewsData, setReviewsData] = useState({
        reviews: [],
        total_reviews: 0,
        average_reviews: 0,
        total_reviews_per_star: []
    });
    const [visibleReviews, setVisibleReviews] = useState(4);
    const [loading, setLoading] = useState(true);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        setVisibleReviews(4);
    };

    const getSelectedReviews = () => {
        if (selectedTab === 'all') {
            return reviewsData.reviews;
        } else if (selectedTab === 'bad') {
            return reviewsData.reviews.filter(review => review.stars < 3);
        } else if (selectedTab === 'good') {
            return reviewsData.reviews.filter(review => review.stars >= 3);
        }
    };
    const selectedReviews = getSelectedReviews();

    const handleLoadMore = () => {
        setVisibleReviews((prev) => prev + 4);
    };

    const handleLoadLess = () => {
        setVisibleReviews(4);
    };

    const displayedReviews = selectedReviews.slice(0, visibleReviews);

    const tabs = [
        {label: 'All', value: 'all'},
        {label: 'Bad Reviews', value: 'bad'},
        {label: 'Good Reviews', value: 'good'}
    ];
    const getReviews = async () => {
        setIsLoading(true)
        try {
            const response = await axios.get(`/beach/${beachId}/get-reviews`);
            setReviewsData({
                reviews: response.data.data.all_reviews,
                total_reviews: response.data.data.total_reviews,
                average_reviews: response.data.data.average_reviews,
                total_reviews_per_star: response.data.data.total_reviews_per_star,
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        getReviews();
    }, []);

    return (
        <>
            {isLoading && (
                <Backdrop
                    open={true}
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            )}
            <div className="px-5 pt-10 mb-8">
                <h2 className="text-primary-dark-green text-2xl font-bold">Reviews</h2>
                <p className="text-primary-gray text-xs pt-2">Read genuine feedback from your guests. <br/>All reviews
                    are submitted anonymously to ensure honesty and transparency.</p>
                <div className="md:grid grid-cols-2 pt-8">
                    <div className="flex items-center gap-10">
                        <div className="text-primary-dark-green">
                            <p className="font-bold text-2xl">{reviewsData.total_reviews}</p>
                            <p className="font-archivo-light">Total Reviews</p>
                        </div>
                        <div className="text-primary-dark-green">
                            <div className="flex items-center gap-2">
                                <p className="font-bold text-2xl">{reviewsData.average_reviews}</p>
                                <CustomRating
                                    name="read-only"
                                    value={parseFloat(reviewsData.average_reviews)}
                                    precision={0.5}
                                    readOnly
                                />
                            </div>
                            <p className="font-archivo-light">Average Reviews</p>
                        </div>
                    </div>
                    <div className="md:px-8 max-md:py-8">
                        {reviewsData.total_reviews_per_star.length > 0 ? (
                            reviewsData.total_reviews_per_star.map((review) => (
                                <div key={review.id} className="py-1 flex items-center gap-3">
                                    <StarIcon style={{color: '#F96300', height: 20}}/>
                                    <p>{review.label}</p>
                                    <Box sx={{flexGrow: 1}}>
                                        <CustomProgressBar
                                            variant="determinate"
                                            value={review.value}
                                        />
                                    </Box>
                                    <p className="text-[#B0CBCE] font-archivo-light">{review.total} stars</p>
                                </div>
                            ))
                        ) : (
                            <p className="text-primary-orange text-sm">No reviews available.</p>
                        )}
                    </div>
                </div>
                <DashboardTab tabs={tabs} selectedTab={selectedTab} onTabChange={handleTabClick}/>
                <div className="md:grid grid-cols-2 gap-3 mt-5">
                    {displayedReviews.length > 0 ? (
                        displayedReviews.map((review, index) => (
                            <div key={index} className="bg-white rounded-lg shadow-2xl p-4 max-md:my-4">
                                <div className="flex items-center justify-between">
                                    <CustomRating
                                        name="read-only"
                                        value={parseFloat(review.stars)}
                                        precision={0.5}
                                        readOnly
                                    />
                                    <p className="text-primary-gray text-sm">{moment(review.created_at).format("dddd MMMM, DD")}</p>
                                </div>
                                <p className="border-b-2 border-[#F3F7F7] my-4"></p>
                                <p className="text-primary-gray text-sm">{review.description}</p>
                            </div>
                        ))
                    ) : (
                        <p className="text-primary-orange text-sm">No reviews available.</p>
                    )}
                </div>
                {selectedReviews.length > 4 && (
                    <div className="flex justify-center gap-2 my-10">
                        {visibleReviews < selectedReviews.length && (
                            <button type="button"
                                    className="bg-primary-orange/20 text-primary-orange rounded-lg px-3 py-1"
                                    onClick={handleLoadMore}>
                                Load More
                            </button>
                        )}
                        {visibleReviews > 4 && (
                            <button type="button"
                                    className="bg-primary-orange/20 text-primary-orange rounded-lg px-3 py-1"
                                    onClick={handleLoadLess}>
                                Load Less
                            </button>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}
export default Reviews;
