import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    modifyMap,
    setItemInformation, setShowSwappingMessage,
    swapSeats
} from "../../../../store/BeachAdmin/BeachAdminActions";
import ShowImageModal from "../../../../components/ShowImageModal";
import {Snackbar, Alert} from '@mui/material';
import {Backdrop, CircularProgress} from "@mui/material";
const OrdersItem = ({item, index }) => {
    const dispatch = useDispatch()
    const { map, } = useSelector((state) => state.beachAdmin)
    const [showImage, setShowImage] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const handleItemClick = () => {
        if(item.isCaptureImage){
            setShowImage(true)
        }
    }

    return (
        <>
            <div
            onClick={handleItemClick}
                style={{
                    display: 'grid',
                    left: item?.startX,
                    top: item?.startY,
                    width: item.width ?? '50px',
                    height: item.height ?? '50px',
                    filter: `${item.is_selected_to_add ? 'invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(96%) contrast(106%)' : ''} `.trim(),
                    // filter: isSwapping && clickedItemIndex !== index && item.can_be_reserved === 1 ? 'drop-shadow(0px 0px 7px #298a3e)' : 'none',
                    color: "black",
                    transform: `rotate(${item.rotateDegree}deg)`,
                    justifyContent: 'center',
                    zIndex: 10,
                    // filter: (item.is_selected_to_add) && 'invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(96%) contrast(106%)'
                }}
                className="absolute">
                {
                    item.isCaptureImage ?
                        <img src={'/' + item.image} height={item.height ?? '50px'} width={item.width ?? '50px'}/>
                        :
                        

                        <>
                        {
                            !item.order || !item.order.seat_number ?
                            <img style={{
                                position: "absolute",
                            }} src={item.image} height={item.height ?? '50px'}
                                 width={item.width ?? '50px'}
                            />
                            :
                            <div>
                                     <img style={{
                                position: "absolute", filter:'brightness(0) saturate(100%) invert(93%) sepia(74%) saturate(3489%) hue-rotate(326deg) brightness(97%) contrast(105%)',
                            }} src={item.image} height={item.height ?? '50px'}
                                 width={item.width ?? '50px'}/>
                            </div>
                        }
                                      {
                                                (item.called_waiter) &&
                                                <span style={{
                                                    zIndex: 20,
                                                    fontWeight: 'bold',
                                                    color: 'white',
                                                    height: 'max-content',
                                                    alignSelf: 'center'
                                                }}>
                                                    <img src="requestWaiter.png" />
                                                    
                                                    </span>
                                            }
                        </>
                }
            </div>

            {
                showImage &&
                <ShowImageModal open={showImage} handleClose={() => setShowImage(false)} imageUrl={item.imageAttached}/>
            }
           
            {isLoading &&
                <Backdrop
                    open={true}
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            }
        </>

    )
}
export default OrdersItem
