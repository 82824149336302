import './style.css'
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from "react-redux";
import { loginAction, loginProvider } from "../../store/Auth/AuthActions.js";
import { toggleBagSideBar } from "../../store/Home/HomeActions";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import PhoneInput from "react-phone-number-input";

const UserSignIn = () => {
    const [loginCreds, setLoginCreds] = useState({
        email: "",
        password: "",
        phone: "",
    });
    const [wrongCredentials, setWrongCredentials] = useState(false);
    const [logInPhoneNumber, setLogInPhoneNumber] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const login = () => {
        const { email, password, phone } = loginCreds;
        const credentials = logInPhoneNumber ? { phone_number:phone.replace('+',''), password } : { email, password };

        if ((logInPhoneNumber && phone && password) || (!logInPhoneNumber && email && password)) {
            dispatch(loginAction(credentials))
                .then((response) => {
                    if (logInPhoneNumber) {
                        localStorage.setItem("phone", phone);
                        localStorage.setItem("loginMethod", "phone");
                    } else {
                        localStorage.setItem("email", email);
                        localStorage.setItem("loginMethod", "email");
                    }
                    handleUserRedirect(response);
                })
                .catch((error) => {
                    setWrongCredentials(true);
                });
        }
    }

    function handleUserRedirect(response) {
        if (!response.email_verified_at) {
            navigate('/verify-email');
            return;
        }
        let fromPage = localStorage.getItem('route_from');
        if (fromPage && fromPage === "checkout") {
            dispatch(toggleBagSideBar(true));
            navigate('/beach-info');
        } else {
            navigate("/");
        }
    }

    const googleLogin = useGoogleLogin({
        onSuccess: async (credentialResponse) => {
            const googleApi = axios.create({
                baseURL: "https://www.googleapis.com/oauth2/v3/userinfo",
            });
            const userInfo = await googleApi({
                headers: { Authorization: `Bearer ${credentialResponse.access_token}` },
            });
            dispatch(loginProvider({
                first_name: userInfo.data.given_name,
                last_name: userInfo.data.family_name,
                email: userInfo.data.email,
                sub: userInfo.data.sub
            }, 'google')).then((response) => {
                handleUserRedirect(response);
            })
                .catch((error) => setWrongCredentials(true));
        },
        onError: () => setWrongCredentials(true),
    });

    const handleFacebookLogin = (response) => {
        dispatch(loginProvider({
            first_name: response.data.last_name,
            last_name: response.data.first_name,
            email: response.data.email,
            sub: response.data.accessToken
        }, 'facebook')).then((response) => {
            handleUserRedirect(response);
        });
    }

    function register() {
        navigate("/register");
    }

    function goBack() {
        navigate(-1);
    }
    function navigateForgotPassword() {
        navigate("/forgotPassword");
    }

    return (
        <section
            className="background-image"
            style={{ backgroundImage: `url(/main-background.png)` }}>
            <div className="overlay">
                <div className="container">
                    <button className="exit mb-5" onClick={goBack}>
                        <CloseIcon />
                    </button>
                    <h1 className="my-5 text-3xl">
                        Sign in
                    </h1>
                    <div className="inputContainers">
                        <div className="flex flex-col">
                            {logInPhoneNumber ? (
                                <PhoneInput
                                    className="inputStyle"
                                    value={loginCreds.phone}
                                    onChange={(phone) =>
                                        setLoginCreds((prev) => ({
                                            ...prev,
                                            phone,
                                        }))
                                    }
                                    placeholder="Phone Number"
                                />
                            ) : (
                                <input
                                    className="inputStyle"
                                    type="text"
                                    value={loginCreds.email}
                                    onChange={(e) =>
                                        setLoginCreds((prev) => ({
                                            ...prev,
                                            email: e.target.value,
                                        }))
                                    }
                                    placeholder="Email"
                                />
                            )}
                            <button
                                type="button"
                                className="text-[#B1CBCF] underline text-xs flex px-3"
                                onClick={() => setLogInPhoneNumber(!logInPhoneNumber)}
                            >
                                {logInPhoneNumber ? "Log In with Email" : "Log In with Phone Number"}
                            </button>
                        </div>
                        <div>
                            <input
                                className="inputStyle"
                                type="password"
                                value={loginCreds.password}
                                onChange={(e) =>
                                    setLoginCreds((prev) => ({
                                        ...prev,
                                        password: e.target.value,
                                    }))
                                }
                                placeholder="Password"
                            />
                            <a
                                className="cursor-pointer text-decoration-underline text-xs text-[#B1CBCF]" onClick={navigateForgotPassword}
                            >
                                Forgot your password?
                            </a>
                        </div>
                    </div>
                    {
                        wrongCredentials &&
                        <div className="row w-100 justify-content-center mt-2">
                            <div className="col-md-6">
                                <div className="alert alert-danger text-center py-2" role="alert">
                                    Invalid credentials
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row justify-content-center w-100">
                        <div className="col-md-3">
                            <button className="buttonStyle py-2" onClick={login}>
                                Login
                            </button>
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <button onClick={() => googleLogin()} className="bg-white p-2 w-10 rounded-full">
                            <img src="google-logo.png" />
                        </button>
                        {/*<FacebookLogin
                            appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID ?? ""}
                            callback={(response) => handleFacebookLogin(response)}
                            render={(renderProps) => (
                                <button onClick={renderProps.onClick} className="bg-white p-2 w-10 rounded-full">
                                    <img src="facebook-logo.png" />
                                </button>
                            )}
                        />*/}
                    </div>
                </div>
                <div className="bg-primary-dark-green">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <h2 className="md:text-2xl max-md:text-xl">Don't have an account?</h2>
                        </div>
                        <div className="row justify-content-center w-100 mt-5">
                            <div className="col-md-3 flex flex-col items-center">
                                <button className="text-white bg-primary-green md:text-xl font-bold max-md:text-md font-bold rounded-full px-5 py-2"
                                        onClick={register}>
                                    Register
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default UserSignIn;
