import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getBeachDashboardInformation,
    getBuilderInfo, modifyMap, setLoading, setMap, handleSelectedDateChange, getOrdersMap, answerWaiter
} from "../../../../store/BeachAdmin/BeachAdminActions";
import {Space} from "react-zoomable-ui";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DisabledAdminSection from "../../../BeachDashboard/BeachMapView/DisabledAdminSection";
import {useNavigate} from "react-router-dom";
import OrdersItem from "./ordersItem";
import LoopIcon from '@mui/icons-material/Loop';
import StatusDropdown from "../../components/StatusDropdown";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const OrdersMap = () => {
    const [loaded, setLoaded] = useState(false);
    const [checkedWaiters, setCheckedWaiters] = useState({});

    const dispatch = useDispatch()
    const {
        mapScreenHeight,
        map,
        orders,
        waitersCalled

    } = useSelector((state) => state.beachAdmin);
    const spaceRef = useRef(null);
    const navigate = useNavigate()
    const initViewPort = async (vp) => {
        vp.setBounds({x: [-1500, 3000], y: [-1500, 3000]});
        vp.camera.centerFitAreaIntoView({
            left: 0,
            top: 0,
            width: window.innerWidth,
            height: mapScreenHeight
        });
    };
    const [selectedReservation, setSelectedReservation] = useState(true);
    const [showSeatNumbers, setShowSeatNumbers] = useState(false);

    const handleWaitersCalledClick = () => {
        setShowSeatNumbers(!showSeatNumbers);
    };

    const handleToggleClick = () => {
        setSelectedReservation(!selectedReservation);
    };
    useEffect(() => {
        dispatch(getBuilderInfo());
    }, [dispatch]);

    const handleReservations = () => {
        navigate('/orders')
    }
    const getMapInformation = () => {
        dispatch(getOrdersMap())
    }
    useEffect(() => {
        // Fetch API data initially
        getMapInformation();

        // Set up interval to fetch API data every 60 seconds
        const intervalId = setInterval(getMapInformation, 60000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const handleCheckboxClick = (callWaiterId) => {
        dispatch(answerWaiter(callWaiterId))
            .then(() => {
                setCheckedWaiters((prev) => ({
                    ...prev,
                    [callWaiterId]: true,
                }));
                dispatch(getOrdersMap())
            })
            .catch((error) => {
                console.error('Failed to answer waiter:', error);
            });
    };

    const getTimeDifference = (timestamp) => {
        const now = new Date();
        const createdTime = new Date(timestamp);
        const diffInSeconds = Math.floor((now - createdTime) / 1000);

        if (diffInSeconds < 60) {
            return 'just now';
        } else if (diffInSeconds < 3600) {
            const minutes = Math.floor(diffInSeconds / 60);
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 86400) {
            const hours = Math.floor(diffInSeconds / 3600);
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else {
            const days = Math.floor(diffInSeconds / 86400);
            return `${days} day${days > 1 ? 's' : ''} ago`;
        }
    };

    return (
        <div className="flex flex-col h-full">
            <div className="flex-grow flex flex-wrap overflow-hidden relative">
                <Space
                    onCreate={initViewPort}
                    pollForElementResizing={true}
                    ref={spaceRef}
                    style={{
                        width: "100%",
                        height: '100%',
                        overflowY: "scroll",
                        overflowX: "scroll",
                        border: '1px solid black',
                        position: 'relative',
                    }}>
                    {
                        map?.map((section, index) => (
                            section.type === 'ITEM' ?
                                (<OrdersItem
                                    key={'item_disabled_dragged_' + index}
                                    index={index}
                                    item={section}
                                />)
                                : (<DisabledAdminSection key={'section_disabled_dragged_' + index} section={section}
                                                         index={index}/>)
                        ))
                    }
                </Space>
                <div
                    className={`md:absolute top-0 right-0 bg-white shadow-lg z-50 mt-3 rounded-xl ${selectedReservation ? 'flex flex-col h-[95vh] md:w-[20vw] overflow-y-auto' : ''} `}>
                    <div className="relative">
                        <button
                            onClick={handleToggleClick}
                            className="bg-white rounded-full absolute p-1 shadow-3xl -top-[20px] -left-[10px]"
                        >
                            {selectedReservation ? (
                                <CloseFullscreenIcon className="text-primary-orange" sx={{fontSize: '20px'}}/>
                            ) : (
                                <OpenInFullIcon className="text-primary-orange" sx={{fontSize: '20px'}}/>
                            )}
                        </button>
                        <p className="text-primary-dark-green font-bold xl:text-lg md:text-sm pt-4 px-2">Orders</p>
                        <div
                            className="xl:flex items-center md:grid justify-between text-primary-dark-green gap-3 px-3 m-2">

                            <div className="md:flex items-center gap-2">
                                <button type="button" onClick={handleReservations}
                                        className="text-white bg-primary-dark-green px-2 py-2 rounded-lg">
                                    <DragIndicatorIcon/>
                                </button>

                                <button type="button" onClick={getMapInformation}
                                        className="bg-primary-dark-green rounded-md py-2 px-2.5 text-sm"><LoopIcon
                                    className="text-white"/></button>
                            </div>
                        </div>
                        {waitersCalled?.length > 0 && (
                            <div className="flex flex-col px-3 py-4">
                                <div
                                    className="flex gap-2 items-center cursor-pointer text-primary-orange"
                                    onClick={handleWaitersCalledClick}
                                >
                                    <img src="../../../../../../svgs/waiters.svg" alt="Waiters Icon"/>
                                    <p>Waiters Called</p>
                                    <p className="font-bold">({waitersCalled.length})</p>
                                    {showSeatNumbers ? (
                                        <KeyboardArrowDownIcon/>
                                    ) : (
                                        <KeyboardArrowRightIcon/>
                                    )}
                                </div>
                                <div className="overflow-y-auto max-h-[12rem]">
                                    {showSeatNumbers && (
                                        <div className="mt-2">
                                            <ul className="text-sm text-primary-gray">
                                                {waitersCalled.map((waiter, index) => (
                                                    <div
                                                        className="flex items-center justify-between py-2 cursor-pointer"
                                                        onClick={() => handleCheckboxClick(waiter.id)}>
                                                        <div className="flex items-center gap-2">
                                                            <input
                                                                type="checkbox"
                                                                checked={checkedWaiters[waiter.id] || false}
                                                                onChange={() => handleCheckboxClick(waiter.id)}
                                                                className="hidden"
                                                                id={`waiter-checkbox-${waiter.id}`}
                                                            />
                                                            <label
                                                                htmlFor={`waiter-checkbox-${waiter.id}`}
                                                                className={`w-5 h-5 flex items-center justify-center rounded border ${
                                                                    checkedWaiters[waiter.id] ? 'bg-primary-dark-green border-[#00AF53]' : 'bg-gray-200 border-gray-300'
                                                                } cursor-pointer`}
                                                            >
                                                                {checkedWaiters[waiter.id] && (
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="h-4 w-4 text-white"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor"
                                                                    >
                                                                        <path strokeLinecap="round"
                                                                              strokeLinejoin="round"
                                                                              strokeWidth={2} d="M5 13l4 4L19 7"/>
                                                                    </svg>
                                                                )}
                                                            </label>
                                                            <li key={index}
                                                                className="text-primary-dark-green font-light">
                                                                Seat #{waiter.seat_number}
                                                            </li>
                                                        </div>
                                                        <p className="text-primary-light-green">{getTimeDifference(waiter.created_at)}</p>
                                                    </div>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <p className="border-b border-primary-gray/10"></p>
                        <div className="px-3">
                            <div className="overflow-y-auto max-h-[80vh]">
                                {orders?.map((order, index) => (
                                    <div
                                        key={order.id}
                                        className={`border rounded-md mb-2 p-3 shadow-sm ${
                                            order.status === 'pending'
                                                ? 'border-yellow-500'
                                                : order.status === 'completed'
                                                    ? 'border-green-500'
                                                    : 'border-red-500'
                                        }`}
                                    >
                                        <div className="flex justify-between items-center">
                                            <p className="font-medium text-lg text-primary-dark-green">
                                                #{order.seat_number}
                                            </p>
                                            <StatusDropdown
                                                params={{
                                                    value: order.status,
                                                    order_id: order.id,
                                                }}
                                                pageType="orders"
                                                onEditCellChangeCommitted={({id, field, value}) => {
                                                    console.log(`Updated Order ID: ${id}, Field: ${field}, Value: ${value}`);
                                                }}
                                            />
                                        </div>
                                        <p className="border-b border-primary-gray/10 mt-2"></p>
                                        <ul className="text-sm mt-4">
                                            {order.order_items.map((item) => (
                                                <li key={item.id} className="flex items-center justify-between mb-1">
                                                    <div>
                                                        <p className="text-primary-dark-green">
                                                            {item.quantity}x {item.item.name}
                                                        </p>
                                                    </div>
                                                    <p className="text-xs text-[#B0CBCE]">
                                                        {getTimeDifference(order.created_at)}
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default OrdersMap
