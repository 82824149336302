import {
    DataGrid,
} from '@mui/x-data-grid';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import {
    Switch
} from "@mui/material";
import ItemCategoryModal from "./modals/ItemCategoryModal";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteModal from "./modals/DeleteModal";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    addNewItem,
    deleteCategory,
    deleteItem,
    getCategories,
    getItems
} from "../../../../../store/MenuBuilder/MenuBuilderAction";
import {useDispatch, useSelector} from "react-redux";

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButton-textPrimary': {
                        color: '#F96300',
                    },
                    '&.MuiButton-sizeSmall': {
                        color: '#F96300',
                    },
                    '&.css-1xfnxoa-MuiButtonBase-root-MuiButton-root': {
                        color: '#F96300',
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontFamily: 'archivo-regular',
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#00414A',
                        color: '#FFFFFF',
                        outline: 'none'
                    },
                    '& .MuiDataGrid-columnHeader': {
                        color: '#FFFFFF',
                        '&:hover .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root': {
                            color: '#FFFFFF',
                        },
                    },
                    '& .MuiDataGrid-columnHeaderCheckbox .MuiSvgIcon-root': {
                        color: '#FFFFFF',
                    },
                    '& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root': {
                        color: '#FFFFFF',
                    },
                    '& .MuiDataGrid-menuIcon': {
                        display: 'flex',
                        color: '#FFFFFF',
                    },
                    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root': {
                        color: '#FFFFFF',
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-selectedRowCount': {
                        color: '#00414A'
                    },
                    '& .MuiTablePagination-selectLabel': {
                        color: '#00414A'
                    },
                    '& .MuiTablePagination-input': {
                        color: '#00414A'
                    },
                    '& .MuiTablePagination-displayedRows': {
                        color: '#00414A'
                    },
                    '& .MuiDataGrid-row.Mui-selected': {
                        backgroundColor: 'transparent',
                        cursor: '22'
                    },
                    '& .MuiDataGrid-row.Mui-selected:hover': {
                        backgroundColor: 'transparent',
                        cursor: 'pointer'
                    },
                    '& .MuiDataGrid-row:hover': {
                        backgroundColor: 'inherit',
                    },
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none !important',
                    },
                    '& .MuiDataGrid-columnHeader:focus': {
                        outline: 'none !important',
                    },
                    '& .MuiDataGrid-row:focus': {
                        outline: 'none !important',
                    },
                    '& .MuiDataGrid-root': {
                        border: 'none !important',
                    },
                    '& .MuiIconButton-sizeMedium': {
                        color: '#F96300'
                    },
                    '& .MuiDataGrid-withBorderColor': {
                        borderColor: 'transparent',
                    },
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                        marginBottom: '20px',
                    }
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: '#F96300',
                    },
                    '&.MuiCheckbox-indeterminate': {
                        color: '#F96300',
                    },
                },
            },
        },
    },
});

const validationSchema = Yup.object().shape({
    categoryImage: Yup.mixed().required('Image is required'),
});

const MenuTable = ({selectedRestaurantId}) => {

    const [selectedOption, setSelectedOption] = useState('categories');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newImagePreview, setNewImagePreview] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [modalType, setModalType] = useState('');
    const [itemName, setItemName] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [price, setPrice] = useState('');
    const [category, setCategory] = useState('');
    const [ingredients, setIngredients] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [description, setDescription] = useState('');

    const items = useSelector((state) => state.menuBuilder.items);
    const categories = useSelector((state) => state.menuBuilder.categories);

    const dispatch = useDispatch();
    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };
    const handleOpenModal = () => {
        setModalType('add');
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setNewImagePreview(null);
        setItemName('');
        setCategoryName('');
    };
    const formik = useFormik({
        initialValues: {
            categoryImage: null,
        },
        enableReinitialize: true,
        validationSchema,
    });

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            formik.setFieldValue('categoryImage', file);
            setNewImagePreview(URL.createObjectURL(file));
        }
    };
    const handleRemoveImage = () => {
        setNewImagePreview(null);
    };

    const handleSave = () => {
        handleCloseModal();
    };
    const handleSwitchButton = async (row) => {
        const beachId = sessionStorage.getItem('beach_id');
        const formData = new FormData();

        const newIsActive = !row.is_active;

        formData.append('_method', 'put');
        formData.append('name', row.name);
        formData.append('price', row.price);
        formData.append('category_id', row.category_id);
        formData.append('is_active', newIsActive ? 1 : 0);
        formData.append('description', row.description);

        if (row.ingredients && Array.isArray(row.ingredients)) {
            row.ingredients.forEach((ingredient, index) => {
                if (ingredient.id) {
                    formData.append(`ingredients[${index}]`, ingredient.id);
                }
            });
        }
        if (row.image && typeof row.image === 'object') {
            formData.append('image', row.image);
        } else if (row.image) {
            formData.append('image', row.image);
        }
        try {
            await dispatch(addNewItem(beachId, selectedRestaurantId, formData, row.id));

            await dispatch(getItems(beachId, selectedRestaurantId));
        } catch (error) {
            console.error('Error updating the item status:', error);
        }
    };
    const handleEditOpen = (row) => {
        setSelectedRow(row);
        setModalType('edit');
        setIsModalOpen(true);

        if (selectedOption === 'items') {
            setItemName(row.name || '');
            setNewImagePreview(row.image || '');
            setPrice(row.price || '');
            setCategory(row.category_id || '');
            setIngredients(row.ingredients ? row.ingredients.map(ing => ing.id) : []);
            setDescription(row.description || '');
            setIsActive(row.is_active === 1);
        } else if (selectedOption === 'categories') {
            setCategoryName(row.name || '');
            setNewImagePreview(row.image || '');
        }
    };
    const rows = selectedOption === 'categories'
        ? (categories.length > 0
            ? categories.map((category,index) => ({
                id: category.id || `cat-${index}`,
                name: category.name,
                image: category.image,
            }))
            : [{id: 'no-data', name: 'No categories found for this restaurant.', image: null}])
        : (items.length > 0
            ? items.map((item) => ({
                id: item.id,
                name: item.name,
                image: item.image,
                price: item.price,
                category_id: item.category_id,
                ingredients: item.ingredients,
                description: item.description,
                is_active: item.is_active
            }))
            : [{id: 'no-data', name: 'No items found for this restaurant.', image: null}]);

    const columns = [
        {
            field: 'image',
            headerName: 'Image',
            width: 150,
            renderCell: (params) => params.row.id !== 'no-data' ? (
                <img
                    src={params.row.image}
                    alt="Item"
                    className="w-14 h-14 object-cover rounded-lg"
                />
            ) : null,
        },
        {
            field: 'name',
            headerName: selectedOption === 'categories' ? 'Category Name' : 'Item Name',
            width: 200,
            renderCell: (params) => (
                <div style={{width: '100%', textAlign: params.row.id === 'no-data' ? 'center' : 'left'}}>
                    {params.row.name}
                </div>
            ),
        },
        ...(selectedOption === 'items' && rows[0].id !== 'no-data' ? [
            {
                field: 'switch',
                headerName: '',
                width: 150,
                renderCell: (params) => (
                    <Switch
                        // checked={params.row.isActive}
                        checked={params.row.is_active === 1}
                        onChange={() => handleSwitchButton(params.row)}
                        sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                                color: '#F96300',
                                '&:hover': {
                                    backgroundColor: 'rgba(249, 99, 0, 0.5)',
                                },
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                backgroundColor: '#F96300',
                            },
                        }}
                    />
                )
            }
        ] : []),
        {
            field: 'actions',
            headerName: '',
            width: 200,
            renderCell: (params) =>
                params.row.id !== 'no-data' ? (
                    <>
                        <button type="button" onClick={() => handleEditOpen(params.row)}
                                className="mx-2 text-primary-light-green">
                            <EditIcon/>
                        </button>
                        <button type="button" onClick={() => handleDeleteOpen(params.row)}
                                className="mx-2 text-[#EE161F]">
                            <DeleteIcon/>
                        </button>
                    </>
                ) : null,
        }
    ];
    const handleDeleteOpen = (row) => {
        setSelectedRow(row);
        setIsDeleteModalOpen(true);
    };
    const handleDeleteConfirm = async () => {
        const beachId = sessionStorage.getItem('beach_id');
        if (beachId && selectedRestaurantId && selectedRow) {
            if (selectedOption === 'items') {
                await dispatch(deleteItem(beachId, selectedRestaurantId, selectedRow.id));
                await dispatch(getItems(beachId, selectedRestaurantId));
            } else if (selectedOption === 'categories') {
                await dispatch(deleteCategory(beachId, selectedRestaurantId, selectedRow.id));
                await dispatch(getCategories(beachId, selectedRestaurantId));
            }
        }
        setIsDeleteModalOpen(false);
        setSelectedRow(null);
    };
    const handleDeleteClose = () => {
        setIsDeleteModalOpen(false);
        setSelectedRow(null);
    };
    useEffect(() => {
        const beachId = sessionStorage.getItem('beach_id');
        if (selectedRestaurantId && beachId) {
            if (selectedOption === 'categories') {
                dispatch(getCategories(beachId, selectedRestaurantId));
            } else if (selectedOption === 'items') {
                dispatch(getItems(beachId, selectedRestaurantId));
            }
        }
    }, [selectedRestaurantId, selectedOption, dispatch]);

    return (
        <div>
            <div className="flex justify-between mb-4">
                <div className="flex gap-2 items-center">
                    <button
                        type="button"
                        className={`rounded-md px-3 py-1 text-sm text-white font-medium ${selectedOption === 'categories' ? 'bg-primary-dark-green' : 'bg-primary-light-green text-primary-light-green'}`}
                        onClick={() => handleOptionChange('categories')}
                    >
                        Categories
                    </button>
                    <button
                        type="button"
                        className={`bg-primary-dark-green text-white rounded-md px-3 py-1 text-sm font-medium ${selectedOption === 'items' ? 'bg-primary-dark-green' : 'bg-primary-light-green text-primary-light-green'}`}
                        onClick={() => handleOptionChange('items')}
                    >
                        Items
                    </button>
                </div>
                <div>
                    <button type="button" onClick={handleOpenModal}
                            className="bg-primary-dark-green text-white rounded-md px-3 py-1 text-sm font-light">Add New
                    </button>
                </div>
            </div>
            <ThemeProvider theme={theme}>
                <div className="w-full overflow-x-auto">
                    {rows.length === 1 && rows[0].id === 'no-data' ? (
                        <div className="flex justify-center items-center text-lg shadow-xl text-primary-orange text-center pt-5 font-bold">
                            {selectedOption === 'categories'
                                ? 'No categories found for this restaurant.'
                                : 'No items found for this restaurant.'
                            }
                        </div>
                    ) : (
                        <div className="md:min-w-[500px]">
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                getRowId={(row) => row.id || row.name || row.customGeneratedId}
                                initialState={{
                                    pagination: {
                                        paginationModel: {page: 0, pageSize: 6},
                                    },
                                }}
                                pageSizeOptions={[5, 6, 10]}
                                editMode="cell"
                                sx={rows.length > 1 ? { height: '400px', '& .MuiDataGrid-row': { paddingTop: '14px' } } : {}}
                            />
                        </div>
                    )}
                </div>
            </ThemeProvider>
            <ItemCategoryModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                restaurantId={selectedRestaurantId}
                type={selectedOption === 'categories' ? 'category' : 'item'}
                onSave={handleSave}
                imagePreview={newImagePreview}
                handleImageChange={handleImageChange}
                handleRemoveImage={handleRemoveImage}
                itemName={itemName}
                categoryName={categoryName}
                categoryImage={newImagePreview}
                price={price}
                category={category}
                ingredients={ingredients}
                description={selectedRow ? selectedRow.description : ''}
                isActive={isActive}
                selectedRow={selectedRow}
                modalType={modalType}
            />
            <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={handleDeleteClose}
                onDelete={handleDeleteConfirm}
                message="Are you sure you want to delete this row?"
            />
        </div>
    )
}
export default MenuTable;
