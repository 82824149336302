import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import MapPage from '../pages/Map/MapPage';
import WelcomePage from '../pages/WelcomePage/WelcomePage';
import HomePage from '../pages/HomePage';
import Plans from '../pages/Plans/Plans';
import BeachInfo from '../pages/BeachInfo';
import Layout from "../pages/sidebar/Layout";
import {RequiresAdminAuthentication, RequiresAuthentication, RequiresBeachAuthentication} from "./ProtectedRoutes";
import AllBeaches from "../pages/AllBeaches";
import SelectSeats from "../pages/SelectSeats";
import UserSignIn from "../pages/UserSignIn";
import UserRegister from "../pages/UserRegister";
import MyBag from "../pages/MyBag";
import SearchedBeaches from "../pages/SearchedBeaches";
import GoogleMapsPage from "../pages/GoogleMapsPage";
import MyAccount from "../pages/MyAccount";
import EditAccount from "../pages/EditAccount";
import UnauthorizedPage from "../pages/UnauthorizedPage";
import OrderComplete from "../pages/OrderComplete";
import BeachDashboard from "../pages/BeachDashboard";
import OtpPage from "../pages/OtpPage";
import BeachDashboardExpanded from "../pages/BeachDashboardExpanded";
import QrReservation from "../pages/QrReservation";
import BeachDashboardReport from "../pages/BeachDashboardReport";
import MyTickets from "../pages/MyTickets";
import GuestCheckoutFlow from "../pages/GuestCheckoutFlow";
import Home from "../pages/Home";
import BeachInfoNew from "../pages/BeachInfoNew";
import LoaderComp from "../components/LoaderComp";
import ForgotPassword from "../pages/UserSignIn/ForgotPassword/ForgotPassword";
import CheckOtp from "../pages/UserSignIn/ForgotPassword/CheckOtp";
import CreateNewPassword from "../pages/UserSignIn/ForgotPassword/CreateNewPassword";
import ContactUs from "../pages/ContactUs";
import Index from "../pages/Dashboard";
import Analytics from "../pages/Dashboard/pages/Analytics";
import {Dashboard} from "@mui/icons-material";
import LogIn from "../pages/Dashboard/pages/Auth/LogIn";
import DashboardLayout from "../pages/Dashboard/pages/layout/DashboardLayout";
import Orders from "../pages/Dashboard/pages/Orders";
import MapBuilder from "../pages/Dashboard/pages/MapBuilder";
import Guests from "../pages/Dashboard/pages/Guests";
import MenuBuilder from "../pages/Dashboard/pages/MenuBuilder";
import Settings from "../pages/Dashboard/pages/Settings";
import Reviews from "../pages/Dashboard/pages/Reviews";
import Reservations from "../pages/Dashboard/pages/Reservations";
import ReservationsMap from "../pages/Dashboard/pages/ReservationsMap";
import TermsConditions from "../pages/Dashboard/pages/TermsConditions";
import PayAtDoor from "../pages/PayAtDoor";
import OrderCompleteMonty from "../pages/OrderComplete/orderCompleteMonty";
import OrderErrorMonty from "../pages/OrderComplete/orderErrorMonty";
import RestaurantsMenu from "../pages/RestaurantsMenu";
import Menu from "../pages/RestaurantsMenu/components/Menu";
import OrderCheckout from "../pages/RestaurantsMenu/components/OrderCheckout";
import OrderMenuComplete from "../pages/RestaurantsMenu/components/OrderMenuComplete";
import OrderMenuError from "../pages/RestaurantsMenu/components/OrderMenuError";
import OrdersMap from "../pages/Dashboard/pages/OrdersMap";

function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/test" element={<LoaderComp/>}/>
                <Route path="/401" element={<UnauthorizedPage/>}/>
                <Route path="/login" element={<LogIn/>}/>
                <Route path="/contactUs" element={<ContactUs/>}/>
                <Route path="/" element={<Home/>}/>
                <Route path="/forgotPassword" element={<ForgotPassword/>}/>
                <Route path="/checkOtp" element={<CheckOtp/>}/>
                <Route path="/createNewPassword" element={<CreateNewPassword/>}/>
                <Route path="/event-qr" element={<QrReservation/>}/>
                <Route path="/login-user" element={<UserSignIn/>}/>
                <Route path="/register" element={<UserRegister/>}/>
                <Route path="/oldHome" element={<HomePage/>}/>
                <Route path="/beach-info" element={<BeachInfoNew/>}/>
                <Route path="/beachInfo" element={<BeachInfoNew/>}/>
                <Route path="/select-seats" element={<SelectSeats/>}/>
                <Route path="/beach" element={<GuestCheckoutFlow/>}/>
                <Route path="/menu" element={<RestaurantsMenu/>}/>
                <Route path="/view-menu" element={<Menu/>}/>
                <Route path="/order-checkout" element={<OrderCheckout/>}/>
                <Route path="/searched-beaches" element={<SearchedBeaches/>}/>
                <Route path="/google-maps" element={<GoogleMapsPage/>}/>
                <Route path="/order-complete-monty" element={<OrderCompleteMonty/>}/>
                    <Route path="/order-error-monty" element={<OrderErrorMonty/>}/>
                    <Route path="/order-menu-complete" element={<OrderMenuComplete/>}/>
                <Route element={<RequiresAuthentication/>}>
                    <Route path="/my-account" element={<MyAccount/>}/>
                    <Route path="/my-tickets" element={<MyTickets/>}/>
                    <Route path="/verify-email" element={<OtpPage/>}/>
                    <Route path="/edit-my-profile" element={<EditAccount/>}/>
                    <Route path="/my-bag" element={<MyBag/>}/>
                    <Route path="/order-complete" element={<OrderComplete/>}/>

                    <Route path="/order-menu-error" element={<OrderMenuError/>}/>
                    <Route path="/pay-at-door" element={<PayAtDoor/>}/>
                </Route>
                <Route element={<RequiresBeachAuthentication/>}>
                    <Route element={<Layout/>}>
                        <Route element={<RequiresAdminAuthentication/>}>
                            <Route path="/all-beaches" element={<AllBeaches/>}/>
                        </Route>
                        <Route path="/map" element={<MapPage/>}/>
                        <Route path="/plans" element={<Plans/>}/>
                    </Route>
                    <Route path="/dashboard" element={<Analytics/>}/>
                    <Route path="/dashboard-expanded" element={<Reservations/>}/>
                </Route>
                <Route path="/login-dashboard" element={<LogIn/>}/>
                <Route path="/terms-conditions" element={<TermsConditions/>}/>
                <Route element={<DashboardLayout/>}>
                    <Route path="/analytics" element={<Analytics/>}/>
                    <Route path="/map-builder" element={<MapBuilder/>}/>
                    <Route path="/reservations" element={<Reservations/>}/>
                    <Route path="/orders" element={<Orders/>}/>
                    <Route path="/map-orders" element={<OrdersMap/>}/>
                    <Route path="/guests" element={<Guests/>}/>
                    <Route path="/reviews" element={<Reviews/>}/>
                    <Route path="/menu-builder" element={<MenuBuilder/>}/>
                    <Route path="/settings" element={<Settings/>}/>
                    <Route path="/reservations/map" element={<ReservationsMap/>}/>
                </Route>
            </Routes>
        </Router>
    );
}

export default AppRouter;
