import React from 'react';
import {Box, Modal, Switch} from '@mui/material';
import ImageUpload from '../ImageUpload';
import {useFormik} from "formik";
import * as Yup from "yup";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    height: '70%',
    overflowY:'auto'
};

const RestaurantModal = ({
                             isOpen,
                             onClose,
                             restaurantName,
                             setRestaurantName,
                             restaurantImagePreview,
                             setRestaurantImagePreview,
                             isActiveMenu,
                             setIsActiveMenu,
                             onSave,
                             isEditing,
                             setNewRestaurantImage,
                         }) => {

    const validationSchema = Yup.object().shape({
        restaurantName: Yup.string().required('Restaurant name is required'),
        restaurantImage: Yup.mixed().nullable().test(
            'required',
            'Restaurant image is required',
            function (value) {
                if (!isEditing) {
                    return value !== null || restaurantImagePreview !== null;
                }
                return true;
            }
        ),
    });

    const formik = useFormik({
        initialValues: {
            restaurantName: restaurantName || '',
            restaurantImage: null,
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values) => {
            const formData = new FormData();
            formData.append('name', values.restaurantName);

            if (values.restaurantImage) {
                formData.append('image', values.restaurantImage);
            } else if (restaurantImagePreview) {
                formData.append('image', restaurantImagePreview);
            }

            formData.append('is_active', isActiveMenu ? '1' : '0');

            onSave(formData);
        },
    });
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            formik.setFieldValue('restaurantImage', file);
            setRestaurantImagePreview(URL.createObjectURL(file));
            setNewRestaurantImage(file);
        }
    };
    const handleRemoveImage = () => {
        formik.setFieldValue('restaurantImage', null);
        setRestaurantImagePreview(null);
        setNewRestaurantImage(null);
    };
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                <div className="flex items-center justify-center">
                    <div className="">
                        <button
                            onClick={onClose}
                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 z-10"
                        >
                            <span className="text-lg">x</span>
                        </button>
                        <h2 className="text-lg font-bold text-primary-dark-green text-center">
                            {isEditing ? "Edit Restaurant" : "Add New Restaurant"}
                        </h2>
                        <p className="text-primary-gray text-sm py-4 text-center">
                            Please include the details of the new restaurant you want to add to your beach.
                        </p>
                        <form onSubmit={formik.handleSubmit}>
                            <div>
                                <p className="text-primary-dark-green font-medium py-2 text-sm">Restaurant Name</p>
                                <input
                                    type="text"
                                    name="restaurantName"
                                    value={formik.values.restaurantName}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        setRestaurantName(e.target.value);
                                    }}
                                    placeholder="Enter Name"
                                    className="w-full p-2 border border-primary-gray font-medium rounded text-sm"
                                />
                                {formik.touched.restaurantName && formik.errors.restaurantName ? (
                                    <p className="text-red-600 text-sm">{formik.errors.restaurantName}</p>
                                ) : null}
                            </div>
                            <ImageUpload
                                imagePreview={restaurantImagePreview}
                                onImageChange={handleImageChange}
                                onRemoveImage={handleRemoveImage}
                                label="Restaurant Image"
                            />
                            {formik.touched.restaurantImage && formik.errors.restaurantImage ? (
                                <p className="text-red-600 text-sm">{formik.errors.restaurantImage}</p>
                            ) : null}

                            <div className="flex items-center mb-4">
                                <Switch
                                    checked={isActiveMenu}
                                    onChange={(e) => setIsActiveMenu(e.target.checked)}
                                    sx={{
                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                            color: '#F96300',
                                            '&:hover': {
                                                backgroundColor: 'rgba(249, 99, 0, 0.5)',
                                            },
                                        },
                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                            backgroundColor: '#F96300',
                                        },
                                    }}
                                />
                                <span className="text-primary-gray text-sm">Active Menu</span>
                            </div>
                            <button
                                type="submit"
                                className="text-center bg-primary-dark-green rounded-lg text-white gap-3 my-3 w-full py-2"
                            >
                                {isEditing ? "Save" : "Add New"}
                            </button>
                        </form>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default RestaurantModal;
