import Header from "../../../components/Header";
import SmallBanner from "../../../components/SmallBanner";
import {smallPageBanner} from "../../../constants/homeData";
import Footer from "../../../components/Footer";

export const OrderMenuError = () => {
    return (
        <div>
            <Header/>
            <SmallBanner data={smallPageBanner}/>
            <div className="grid text-center justify-center">
                <img src="order-error.png" width={400} />
                <div className="text-center">
                    <h1 className={"text-primary-dark-green text-3xl max-md:text-xl"}>An Error Has Occured!</h1>
                    <p className={"text-primary-dark-green"}>We're fixing it please be patient !</p>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
export default OrderMenuError
