import {Box, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {deleteProfileAction} from "../../store/Auth/AuthActions.js";

export const DeleteProfileModal = ({open , handleClose}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 400,
        height: 200,
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        boxShadow: 24,
        borderRadius: '15px',
        outline: 0,
    };
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const deleteProfile = async () => {
        await dispatch(deleteProfileAction())
        navigate('/')
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}>
            <Box sx={style} className="md:h-[70vh] md:w-[60%] max-md:w-[80%] max-md:h-[50%]">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-1">
                            <div className="float-end">
                                <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3 className="font-bold text-xl text-primary-dark-green">
                                Delete Your Account</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mt-3 text-center">
                            <p className="font-bold text-md text-primary-dark-green">
                                Are you sure you want to delete your profile?</p>
                        </div>
                    </div>
                    <div className="flex flex-row mt-3 justify-between px-3">
                        <button onClick={handleClose} className={"bg-gray-500 text-white p-2 rounded-lg"}>Cancel</button>
                        <button onClick={deleteProfile} className={"bg-red-500 text-white p-2 rounded-lg"}>Delete</button>
                    </div>

                </div>
            </Box>
        </Modal>
    )
}
export default DeleteProfileModal
