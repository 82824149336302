import React from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const ImageUpload = ({
                         imagePreview,
                         onImageChange,
                         onRemoveImage,
                     }) => {
    return (
        <div>
            <p className="text-primary-dark-green font-medium py-2 text-sm">Image</p>
            {!imagePreview ? (
                <label className="flex items-center border border-primary-gray rounded px-3 py-2 cursor-pointer text-sm">
                    <input
                        type="file"
                        onChange={onImageChange}
                        className="hidden"
                        accept="image/*"
                    />
                    <span className="flex-grow text-primary-gray">Upload Image</span>
                    <span className="text-primary-light-green">
                        <FileUploadIcon />
                    </span>
                </label>
            ) : (
                <div className="relative mb-4">
                    <img
                        src={imagePreview}
                        alt="Selected Preview"
                        className="h-32 object-cover rounded"
                    />
                    <button
                        onClick={onRemoveImage}
                        className="absolute top-1 mx-2 bg-primary-dark-green text-white rounded-full px-2 py-1 text-xs"
                    >
                        ✕
                    </button>
                </div>
            )}
        </div>
    );
};

export default ImageUpload;
