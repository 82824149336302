import React, {useState, useRef, useEffect} from "react";
import IosShareIcon from '@mui/icons-material/IosShare';
import AllRestaurants from "./components/AllRestaurants";
import MenuTable from "./components/MenuTable";
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import {useDispatch} from "react-redux";
import {activateMenu, getRestaurants} from "../../../../store/MenuBuilder/MenuBuilderAction";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
const MenuBuilder = () => {

    const dispatch = useDispatch();
    const [selectedRestaurantId, setSelectedRestaurantId] = useState(null);
    const [menuActive, setMenuActive] = useState(false);
    const [menuUrl, setMenuUrl] = useState("");
    const inputRef = useRef(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleRestaurantSelect = (restaurantId) => {
        setSelectedRestaurantId(restaurantId);
    };
    const handleActiveMenu = async () => {
        let beachId = sessionStorage.getItem('beach_id');
        try {
            const menuUrl = await dispatch(activateMenu(beachId));
            setMenuUrl(menuUrl);
            setMenuActive(true);
        } catch (error) {
            console.error("error", error);
        }
    };
    const handleShareClick = () => {
        if (menuUrl) {
            const fullMenuUrl = window.location.origin + menuUrl;

            navigator.clipboard.writeText(fullMenuUrl)
                .then(() => {
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                    setSnackbarOpen(true);
                })
                .catch((err) => {
                    console.error("error", err);
                });
        }
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    useEffect(() => {
        const beachId = sessionStorage.getItem('beach_id');
        if (beachId) {
            dispatch(getRestaurants(beachId)).then((data) => {
                setMenuActive(data.menu_active === 1);
                setMenuUrl(data.menu_url);
            });
        }
    }, []);

    return (
        <div className="container pt-10">
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Link copied!
                </Alert>
            </Snackbar>
            <div className="flex justify-between items-center">
                <div>
                    <p className="text-primary-dark-green font-bold text-xl pb-4">
                        Menu Builder
                    </p>
                    <p className="text-primary-gray">
                        Please review the menus and consider expanding the offerings at your beach.<br/> You can also
                        request
                        any waiter's assistance at your beach.
                    </p>
                </div>
                {menuActive ? (
                    <IosShareIcon className="text-primary-orange mx-4 cursor-pointer" onClick={handleShareClick}/>
                ) : (
                    <div className="flex items-center bg-primary-orange rounded-full px-2 text-white text-md">
                        <CircleNotificationsIcon/>
                        <button type="button" onClick={handleActiveMenu}>
                            Activate Menu
                        </button>
                    </div>
                )}
            </div>
            <div className="grid grid-cols-1 gap-10 pt-5 md:grid-cols-1 lg:grid-cols-[1fr_3fr] lg:gap-20">
                <AllRestaurants onRestaurantSelect={handleRestaurantSelect}/>
                <MenuTable selectedRestaurantId={selectedRestaurantId}/>
            </div>
        </div>
    )
}
export default MenuBuilder;
