import {smallPageBanner} from "../../../constants/homeData";
import SmallBanner from "../../../components/SmallBanner";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";

export const OrderMenuComplete = () => {
    return (
        <div>
            <Header/>
            <SmallBanner data={smallPageBanner}/>
            <div className="grid text-center justify-center">
                <img src="/success-payment.png" width={400} />
                <div className="text-center">
                    <h1 className={"text-primary-dark-green text-3xl max-md:text-xl"}>You're all set!</h1>
                    <p className={"text-primary-dark-green"}>Yor Order is on the way !</p>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
export default OrderMenuComplete
