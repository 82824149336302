import React, {useState, useEffect} from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import DeleteModal from "./modals/DeleteModal";
import RestaurantModal from "./modals/RestaurantModal";
import {useDispatch, useSelector} from "react-redux";
import {addNewRestaurant, deleteRestaurant, getRestaurants} from "../../../../../store/MenuBuilder/MenuBuilderAction";
import {Backdrop, CircularProgress} from "@mui/material";

const AllRestaurants = ({onRestaurantSelect}) => {
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newRestaurantName, setNewRestaurantName] = useState("");
    const [newRestaurantImage, setNewRestaurantImage] = useState(null);
    const [newRestaurantImagePreview, setNewRestaurantImagePreview] = useState(null);
    const [isActiveMenu, setIsActiveMenu] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedRestaurantToDelete, setSelectedRestaurantToDelete] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [restaurantId, setRestaurantId] = useState(null);

    const dispatch = useDispatch();
    const {restaurants,isLoading} = useSelector((state) => state.menuBuilder);

    const handleRestaurantClick = (restaurant) => {
        setSelectedRestaurant(restaurant.id);
        localStorage.setItem('restaurant_id', restaurant.id);
        onRestaurantSelect(restaurant.id, restaurant.beach_id);
    };
    const handleSwitchButton = async (e, restaurant) => {
        e.stopPropagation();
        const newIsActiveMenu = restaurant.is_active === 1 ? 0 : 1;

        const beachId = sessionStorage.getItem('beach_id');
        const formData = new FormData();
        formData.append('name', restaurant.name);
        formData.append('is_active', newIsActiveMenu);

        if (restaurant.image) {
            formData.append('image', restaurant.image);
        }

        formData.append('_method', 'put');

        try {
            await dispatch(addNewRestaurant(beachId, formData, restaurant.id));
            dispatch(getRestaurants(beachId));
        } catch (error) {
            console.error('Failed to update the restaurant status:', error);
        }
    };
    const handleAddNewRestaurant = () => {
        setIsEditing(false);
        setNewRestaurantName("");
        setNewRestaurantImage(null);
        setNewRestaurantImagePreview(null);
        setIsActiveMenu(false);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setNewRestaurantName("");
        setNewRestaurantImage(null);
        setIsActiveMenu(false);
    };
    const handleSaveRestaurant = (formData) => {
        const beachId = sessionStorage.getItem('beach_id');
        if (isEditing && restaurantId) {
            formData.append('_method', 'put');
            dispatch(addNewRestaurant(beachId, formData, restaurantId)).then(() => {
                dispatch(getRestaurants(beachId));
            });
        } else {
            dispatch(addNewRestaurant(beachId, formData)).then(() => {
                dispatch(getRestaurants(beachId));
            });
        }
        handleCloseModal();
    };

    const handleDeleteRestaurantOpen = (restaurant) => {
        setSelectedRestaurantToDelete(restaurant);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setIsDeleteModalOpen(false);
        setSelectedRestaurantToDelete(null);
    };
    const handleDeleteRestaurant = () => {
        const beachId = sessionStorage.getItem('beach_id');
        if (beachId && selectedRestaurantToDelete) {
            dispatch(deleteRestaurant(beachId, selectedRestaurantToDelete.id));
        }
        setIsDeleteModalOpen(false);
        setSelectedRestaurantToDelete(null);
    };
    const handleEditRestaurant = (restaurant) => {
        setIsEditing(true);
        setRestaurantId(restaurant.id);
        setNewRestaurantName(restaurant.name);
        setNewRestaurantImage(null);
        setNewRestaurantImagePreview(restaurant.image);
        setIsActiveMenu(restaurant.is_active === 1);
        setIsModalOpen(true);
    };

    useEffect(() => {
        const beachId = sessionStorage.getItem('beach_id');
        if (beachId) {
            dispatch(getRestaurants(beachId));
        }
    }, []);
    useEffect(() => {
        if (restaurants?.length && !selectedRestaurant) {
            handleRestaurantClick(restaurants[0]);
        }
    }, [restaurants, selectedRestaurant]);

    return (
        <div>
            {isLoading &&
                <Backdrop
                    open={true}
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            }
            <div>
                {
                    restaurants?.map((restaurant, index) => (
                        <div
                            key={restaurant.id ? `restaurant-${restaurant.id}-${index}` : `restaurant-${index}`}
                            className="relative flex flex-col gap-3 bg-white rounded-2xl px-3 py-3 border-2 border-primary-gray/10 my-3 font-medium cursor-pointer"
                            onClick={() => handleRestaurantClick(restaurant)}
                        >
                            {selectedRestaurant === restaurant.id && (
                                <div
                                    className="absolute top-0 left-0 h-full w-3 bg-primary-dark-green rounded-tl-2xl rounded-bl-2xl z-50"></div>
                            )}
                            <div className="flex items-center gap-3 relative">
                                <img src={restaurant.image} className="rounded-md w-16 h-16" alt={`${restaurant.name}`} />
                                <p className="text-primary-dark-green text-sm">{restaurant.name}</p>
                            </div>
                            {selectedRestaurant === restaurant.id && (
                                <div className="flex items-center justify-between gap-3 mt-3">
                                    <div className="text-primary-light-green">
                                        <Switch
                                            checked={restaurant.is_active === 1}
                                            onChange={(e) => handleSwitchButton(e, restaurant)}
                                            sx={{
                                                '& .MuiSwitch-switchBase.Mui-checked': {
                                                    color: '#F96300',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(249, 99, 0, 0.5)',
                                                    },
                                                },
                                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                    backgroundColor: '#F96300',
                                                },
                                            }}
                                        />
                                        Active Menu
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <button
                                            className="text-primary-light-green"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleEditRestaurant(restaurant);
                                            }}
                                        >
                                            <EditIcon />
                                        </button>
                                        <button
                                            className="text-primary-light-green"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleDeleteRestaurantOpen(restaurant);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                }
                <button type="button" onClick={handleAddNewRestaurant}
                        className="text-primary-light-green text-sm px-3"> + Add New Restaurant
                </button>
            </div>
            <RestaurantModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                setRestaurantName={setNewRestaurantName}
                restaurantName={newRestaurantName}
                restaurantImage={newRestaurantImage}
                restaurantImagePreview={newRestaurantImagePreview}
                setRestaurantImagePreview={setNewRestaurantImagePreview}
                isActiveMenu={isActiveMenu}
                setNewRestaurantImage={setNewRestaurantImage}
                setIsActiveMenu={setIsActiveMenu}
                onSave={handleSaveRestaurant}
                isEditing={isEditing}
            />
            <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={handleDeleteModalClose}
                onDelete={handleDeleteRestaurant}
                message={`Are you sure you want to delete the restaurant ${selectedRestaurantToDelete?.name}?`}
            />
        </div>
    )
}
export default AllRestaurants;
