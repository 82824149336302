import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
};

const DeleteModal = ({ isOpen, onClose, onDelete, message }) => {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="delete-modal-title"
            aria-describedby="delete-modal-description"
        >

            <Box sx={modalStyle}>
                <button
                    onClick={onClose}
                    className="absolute top-1 right-2 text-gray-600 hover:text-gray-900 mx-2"
                >
                    <span className="text-lg">x</span>
                </button>
                <p className="text-center text-primary-dark-green text-lg mb-4 mt-2">
                    {message}
                </p>

                <div className="flex items-center justify-center gap-3">
                    <button type="button" onClick={onDelete}
                            className="bg-primary-light-green text-primary-dark-green px-3 py-2 rounded-lg">
                        Yes
                    </button>
                    <button type="button" onClick={onClose} className="bg-[#EE161F] text-white px-3 py-2 rounded-lg">
                        No
                    </button>
                </div>
            </Box>
        </Modal>
    );
};

export default DeleteModal;
