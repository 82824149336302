import CloseIcon from "@mui/icons-material/Close";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {checkOtp, createPasswordAction, registerAction} from "../../../store/Auth/AuthActions.js";
import {toggleBagSideBar} from "../../../store/Home/HomeActions";
import {useDispatch} from "react-redux";
import * as yup from "yup";
import {ErrorMessage, Field, Formik} from "formik";
import PhoneInput from "react-phone-number-input";

const CreateNewPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [setupInfo, setSetupInfo] = useState({
        password_confirmation: '',
        password: ''
    });
    const setupProfileSchema = yup.object().shape({
        password: yup
            .string()
            .required("Password is required")
            .min(8, "Password must be 8 characters at minimum")
            .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
            .matches(/[0-9]/, "Password must contain at least one number")
            .matches(
                /[!@#$%^&*(),.?":{}|<>]/,
                "Password must contain at least one symbol"
            ),
        password_confirmation: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Confirm new password is required"),


    });

    function goBack() {
        navigate(-1)
    }

    const createPassword = (values) => {
        dispatch(createPasswordAction(values.password, values.password_confirmation))
            .then(() => {
                navigate('/login-user');
            })
            .catch((error) => {
                console.error('Failed to create new password:', error);
            });
    };

    return (
        <section
            className="background-image"
            style={{backgroundImage: `url(/main-background.png)`}}>
            <div className="overlay">
                <div className="container">
                    <button className="exit mb-5" onClick={goBack}>
                        <CloseIcon/>
                    </button>
                    <h1 className="my-5 text-3xl max-md:text-center">
                        Enter your new password
                    </h1>
                    <div className="">
                        <Formik
                            initialValues={setupInfo}
                            validationSchema={setupProfileSchema}
                            onSubmit={(values) => {
                                createPassword(values)
                            }}>
                            {({handleSubmit, values, setValues}) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="md:flex gap-2">
                                        <div className="row justify-content-center mt-3">
                                            <div className={"w-full"}>
                                                <Field
                                                    name="password"
                                                    className="inputStyleDark"
                                                    type="password"
                                                    placeholder="Password"
                                                />
                                                <ErrorMessage
                                                    name="password"
                                                    component="div"
                                                    className="error"
                                                />
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mt-3">
                                            <div className={"w-full"}>
                                                <Field
                                                    name="password_confirmation"
                                                    className="inputStyleDark"
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                />
                                                <ErrorMessage
                                                    name="password_confirmation"
                                                    component="div"
                                                    className="error"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row justify-content-center mt-2">
                                        <div className="col-md-6">
                                            <button className="buttonStyleDark text-lg py-2" type="submit"
                                                    onClick={createPassword}>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                    {/*<div className="row justify-content-center w-100">*/}
                    {/*    <div className="col-md-3">*/}
                    {/*        <button className="buttonStyle py-2" onClick={createPassword}>*/}
                    {/*            Create Password*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </section>
    )
}
export default CreateNewPassword;
