import {Outlet, useLocation} from "react-router-dom";
import DashboardHeader from "../../components/Header.jsx";
import React, {useEffect, useState} from "react";
import ItemDetailsSection from "../../../BeachDashboard/BeachMapView/ItemDetailsSection";
import {useSelector} from "react-redux";

const DashboardLayout = () => {
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const { showItemModal } = useSelector((state) => state.beachAdmin)

    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth < 768);
            if (window.innerWidth >= 768) {
                setShowMenu(false);
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            {!isMobileScreen &&
                <div className="flex h-screen">
                    <DashboardHeader className="flex-none h-full"/>
                    <div className="flex-grow overflow-auto">
                        <Outlet/>
                    </div>
                </div>
            }
            {isMobileScreen &&
                <div className="min-h-screen flex flex-col">
                    <DashboardHeader/>
                    <div className="">
                        <Outlet/>
                    </div>
                </div>
            }
            {
                showItemModal && <ItemDetailsSection/>
            }
        </>
    )
}
export default DashboardLayout;
