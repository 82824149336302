import Header from "../../components/Header";
import moment from "moment";
import {checkIfWeekend} from "../../util";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {changeSplittedItemEmail, getMyTickets, refundReservationAction} from "../../store/Auth/AuthActions.js";
import RefundModal from "./RefundModal";
import LoaderComp from "../../components/LoaderComp";
import SmallBanner from "../../components/SmallBanner";
import {smallPageBanner} from "../../constants/homeData";
import Footer from "../../components/Footer";
import ChangeEmailModal from "./ChangeEmailModal";

const MyTickets = () => {
    const {
        myTickets,
        isLoading,
        mySplits,
    } = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const [refundReservation, setRefundReservation] = useState()
    const [showModal, setShowModal] = useState()
    const [showError, setShowError] = useState(false)
    const [emailModal, setEmailModal] = useState()
    const [itemToSplit, setItemToSplit] = useState()

    useEffect(() => {
        dispatch(getMyTickets())
    }, []);
    const downloadQr = (qrCode) => {
        let qrLink = process.env.REACT_APP_BACKEND_URL_STORAGE + 'qrImages/' + qrCode
        const link = document.createElement('a');
        link.href = qrLink;
        link.setAttribute('download', 'qrCode.svg');
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const canRefund = (item) => {
        if (!item.beach.refund_number_of_days) {
            return false;
        }
        let currentDate = new Date();
        let reservationDate = new Date(item.reservation_date)
        const diffTimeInMilliseconds = currentDate - reservationDate;

        if (diffTimeInMilliseconds >= 0) {
            return false;
        }
        const diffDays = Math.ceil(Math.abs(diffTimeInMilliseconds) / (1000 * 60 * 60 * 24));
        return diffDays >= Number(item.beach.refund_number_of_days)
    }
    const handleRefund = (item) => {
        setRefundReservation(item)
        setShowModal(true)
    }

    const handleSplitSelect = (item) => {
        setItemToSplit(item)
        setEmailModal(true)
    }
    const handleCloseModal = () => {
        setRefundReservation({})
        setShowModal(false)
    }
    const handleCloseEmailModal = () => {
        setItemToSplit({})
        setEmailModal(false)
        setShowError(false)
    }
    const handleRefundModal = () => {
        dispatch(refundReservationAction(refundReservation, myTickets)).then((res) => {
            setShowModal(false)
        })
    }
    const handleSubmitEmailChanged = (email) => {
        dispatch(changeSplittedItemEmail(itemToSplit, email, mySplits))
            .then((res) => {
                handleCloseEmailModal()
                dispatch(getMyTickets())

            })
            .catch(() => {
                setShowError(true)
            })
    }

    return (
        <div>
            <Header/>
            <SmallBanner data={smallPageBanner}/>
            {
                isLoading ?
                    <LoaderComp/>
                    :
                    <>
                        <div className="container-fluid mt-5 px-lg-5 px-md-5">
                            <div className="row mt-3">
                                <div className="mx-2">
                                    <h2 className="text-2xl text-primary-dark-green uppercase font-bold">Your
                                        Tickets</h2>
                                    <p className="border-b-2 border-primary-orange w-10 flex items-centerms text-center mb-8"></p>
                                </div>
                            </div>
                            {
                                myTickets.length > 0 ?
                                    <div className="max-md:max-w-[450px] max-md:overflow-x-auto">
                                        <div className="max-md:w-[1000px]">
                                            <div className="card mx-2 bg-gradient-to-t from-[#00414A] to-[#005662]">
                                                <div className="grid grid-cols-5 my-3 text-sm md:text-lg px-2.5">
                                                    <h2 className="text-white">Beach</h2>
                                                    <h2 className="text-white">Date</h2>
                                                    <h2 className="text-white">Item</h2>
                                                    <h2 className="text-white px-4">Price Paid</h2>
                                                    <h2 className="text-white">QR Code</h2>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="card bg-white shadow-md mt-3 py-2 mx-2">
                                                    {
                                                        myTickets.map((item, index) => (
                                                            item.reservation_type === 'default' ?
                                                                <div
                                                                    className="grid grid-cols-5 mt-3 text-primary-gray max-md:text-sm">
                                                                    <p>{item.beach.name}</p>
                                                                    <p> {moment(item.reservation_date).format("dddd MMMM, DD")} </p>
                                                                    <p>{item.item.name}</p>
                                                                    <p className="px-4">
                                                                        {
                                                                            item.amount_per_reservation ? '$' + item.amount_per_reservation : '-'
                                                                        }
                                                                    </p>
                                                                    {
                                                                        item.qr_code ?
                                                                            <img style={{cursor: 'pointer'}}
                                                                                 onClick={() => downloadQr(item.qr_code)}
                                                                                 src={process.env.REACT_APP_BACKEND_URL_STORAGE + 'qrImages/' + item.qr_code}
                                                                                 height={40} width={40}/>
                                                                            :
                                                                            '-'
                                                                    }
                                                                    {
                                                                        canRefund(item) &&
                                                                        <button onClick={() => handleRefund(item)}
                                                                                className={"btn btn-warning"}>Refund
                                                                        </button>
                                                                    }
                                                                </div>
                                                                :
                                                                <div
                                                                    className="grid grid-cols-5 mt-3 text-primary-gray text-xs">
                                                                    <p>{item.beach.name}</p>
                                                                    <p> {moment(item.selected_date).format("dddd MMMM, DD")} </p>
                                                                    <p>Day Pass</p>
                                                                    <p>{item.count}</p>
                                                                    <p className="px-4">
                                                                        $ {item.count * item.beach.day_pass_price}
                                                                    </p>
                                                                    <u><h5>Download
                                                                        Qr</h5></u>
                                                                </div>

                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row mt-5">
                                        <h4 className={"header-text"}>You don't have any upcoming reservations yet.</h4>
                                    </div>
                            }
                            {
                                mySplits.length > 0 &&
                                <div className="row mt-5">
                                    <div className="row mt-3">
                                        <div className="">
                                            <h2 className="text-2xl text-primary-dark-green uppercase font-bold">Shared
                                                Bills</h2>
                                            <p className="border-b-2 border-primary-orange w-10 flex items-centerms text-center mb-8"></p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="card bg-gradient-to-t from-[#00414A] to-[#005662]">
                                            <div className="grid grid-cols-5 py-2.5 md:text-lg text-sm text-white">
                                                <h2>Beach</h2>
                                                <h2>Date</h2>
                                                <h2>Email</h2>
                                                <h2>Status</h2>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="card bg-white shadow-md mt-3">
                                            {
                                                mySplits.map((item, index) => (
                                                    <div className="grid grid-cols-5 align-items-center py-3">
                                                        <div className="">
                                                            <p className={"text-primary-gray text-sm max-md:text-xs"}>{item.beach.name}</p>
                                                        </div>
                                                        <div className="">
                                                            <p className={"text-primary-gray max-md:text-xs"}> {moment(item.reservation_date).format("dddd MMMM, DD")} </p>
                                                        </div>
                                                        <div className="">
                                                            <p className={"text-primary-gray max-md:text-xs"}>{item.user.email}</p>
                                                        </div>

                                                        {
                                                            item.status === 'pending' ?
                                                                <>
                                                                    <div className="">
                                                                        <p style={{color: 'red'}}
                                                                           className={"text-primary-gray max-md:text-xs"}>Pending</p>
                                                                    </div>
                                                                </>
                                                                :
                                                                <div className="">
                                                                    <p style={{color: 'green'}}
                                                                       className={"text-primary-gray"}>Paid</p>
                                                                </div>
                                                        }
                                                        {
                                                            item.status === 'pending' &&
                                                            <div>
                                                                <button onClick={() => handleSplitSelect(item)}
                                                                        className={"btn btn-primary bg-primary-dark-green hover:bg-primary-dark-green max-md:text-xs"}>Edit
                                                                    Email
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            showModal &&
                            <RefundModal open={showModal} handleRefund={handleRefundModal}
                                         handleClose={handleCloseModal} ticket={refundReservation}/>
                        }
                        {
                            emailModal &&
                            <ChangeEmailModal open={emailModal} handleClose={handleCloseEmailModal}
                                              handleEmailChange={handleSubmitEmailChanged} showError={showError}/>
                        }
                    </>
            }
            <Footer/>
        </div>
    )
}
export default MyTickets
