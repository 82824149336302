import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const BeachRestaurants = ({restaurants}) => {
    const navigate = useNavigate();
    const filteredRestaurants = restaurants.filter(restaurant => restaurant.is_active === 1);

    const handleViewMenu = (restaurant) => {
        localStorage.setItem('restaurant_id', restaurant.id);

        localStorage.setItem('selectedRestaurant', JSON.stringify(restaurant));
        navigate(`/view-menu?restaurantId=${restaurant.id}`);
    };
    // useEffect(() => {
    //     if (filteredRestaurants.length === 1) {
    //         const restaurant = filteredRestaurants[0];
    //         localStorage.setItem('selectedRestaurant', JSON.stringify(restaurant));
    //         navigate(`/view-menu?restaurantId=${restaurant.id}`);
    //     }
    // }, [filteredRestaurants, navigate]);

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mx-20">
            {filteredRestaurants && filteredRestaurants.length > 0 ? (
                filteredRestaurants.map((restaurant) => (
                    <div
                        key={restaurant.id}
                        className="bg-white/8 backdrop-blur-md rounded-xl shadow-lg p-8 mt-5"
                    >
                        <div className="flex flex-col items-center text-center">
                            <img
                                src={restaurant.image}
                                alt={restaurant.name}
                                className="w-16 h-16 mb-4 rounded-lg"
                            />
                            <h2 className="text-white text-xl font-semibold mb-6 pt-2">
                                {restaurant.name}
                            </h2>
                            <button
                                type="button"
                                onClick={() => handleViewMenu(restaurant)}
                                className="bg-primary-dark-green hover:bg-teal-700 text-white py-2 px-6 rounded-full text-sm focus:outline-none w-full"
                            >
                                View Menu
                            </button>
                        </div>
                    </div>
                ))
            ) : (
                <p className="text-white text-center">No restaurants available.</p>
            )}
        </div>
    )
}
export default BeachRestaurants;
