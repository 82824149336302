import {homePageBanner} from "../../constants/homeData";
import Banner from "../../components/Banner";
import DefaultSlider from "../../components/DefaultSlider";
import DownloadApp from "./components/DownloadApp";
import Footer from "../../components/Footer";
import FilteredSlider from "../../components/FilteredSlider";
import {setupProfileAction} from "../../store/Auth/AuthActions.js";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {Box, Modal, Snackbar} from "@mui/material";
import {ErrorMessage, Field, Formik} from "formik";
import {useHomePageInfo} from "./hooks";
import LoaderComp from "../../components/LoaderComp";
import Header from "../../components/Header";
import {Alert} from "@mui/lab";
import {setShowSnackbar} from "../../store/Auth/AuthSlice.js";
import {styled} from '@mui/material/styles';
import {updateEmail} from "../../store/Home/HomeActions";
import PhoneInput from "react-phone-number-input";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: 'white',
    boxShadow: 24,
    borderRadius:'15px',
    outline:0
};

export const Home = () => {
    const StyledBox = styled(Box)(({theme}) => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        backgroundColor: 'white',
        boxShadow: 24,
        borderRadius: '15px',
        outline: 0,
        [theme.breakpoints.down('sm')]: {
            width: 350,
        },
    }));
    const dispatch = useDispatch();
    const {isLoading} = useSelector((state) => state.home);
    const {emailError} = useSelector((state) => state.homePage);
    const homePageData = useSelector((state) => state.homePage.homePageData);
    const showSnackbar = useSelector(state => state.auth.showSnackbar);
    const [showModal , setShowModal] = useState(false)

    const handleCloseSnackbar = () => {
        dispatch(setShowSnackbar(false));
    };
    const [showEmailModal, setShowEmailModal] = useState(false);

    const [setupInfo, setSetupInfo] = useState({
        email: ''
    });

    const emailSchema = yup.object().shape({
        email: yup.string().email("Invalid email").required("Email is required"),
    });

    const setupProfileSchema = yup.object().shape({
        first_name: yup.string().required("First Name is required"),
        last_name: yup.string().required("Last Name is required"),
        password: yup
            .string()
            .required("Password is required")
            .min(8, "Password must be 8 characters at minimum")
            .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
            .matches(/[0-9]/, "Password must contain at least one number")
            .matches(
                /[!@#$%^&*(),.?":{}|<>]/,
                "Password must contain at least one symbol"
            ),
        password_confirmation:  yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Confirm new password is required"),
        phone_number: yup.string().required("Phone Number is required"),

    });
    const handleEmailSubmit = async (values) => {
        dispatch(updateEmail(values.email));
        setShowEmailModal(false);
    };

    useEffect(() => {
        const email = localStorage.getItem('email');
        const loginMethod = localStorage.getItem('loginMethod');
        if (!email && loginMethod === 'phone') {
            setShowEmailModal(true);
        }
    }, []);

    const {} = useHomePageInfo();

    const firstSectionRef = useRef(null);

    const scrollToFirstSection = () => {
        firstSectionRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setShowSnackbar(false));
    };
    const setupProfile = (values) =>{
        dispatch(setupProfileAction(values))
            .then(()=>{
                setShowModal(false)
            })
    }

    useEffect(()=>{{
        let firstName = localStorage.getItem('firstName')
        let lastName = localStorage.getItem('lastName')
        let token = localStorage.getItem('token')
        if(token && (!firstName || !lastName || firstName === 'null' || lastName === 'null')){
            setShowModal(true)
        }
    }},[dispatch])

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={showSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Cash on delivery is successful! Check your email.
                </Alert>
            </Snackbar>
            <Header hasBackground={false}/>
            <Banner data={homePageBanner} onScrollClick={scrollToFirstSection}/>
            <div ref={firstSectionRef}>
                {homePageData && homePageData.map((data, index) => {
                    switch (data.type) {
                        case 'content':
                            return (
                                <div key={index} className="container">
                                    <div className="flex flex-col py-4 md:gap-4 max-md:gap-2 px-8">
                                        <h2 className="md:text-2xl max-md:text-lg text-primary-dark-green uppercase pt-8 font-bold">{data.title}</h2>
                                        <p className="border-b-2 border-primary-orange w-10 flex items-centerms text-center mb-8"></p>
                                        <DefaultSlider slidesData={data.beaches}/>
                                    </div>
                                </div>
                            );
                        case 'download_now_banner':
                            return <DownloadApp key={index}/>;
                        case 'default_slider':
                            return (
                                <div key={index} className="container">
                                    <div className="flex flex-col md:gap-4 max-md:gap-2 px-8 ">
                                        <h2 className="md:text-2xl max-md:text-lg text-primary-dark-green uppercase font-bold pt-8">{data.title}</h2>
                                        <p className="border-b-2 border-primary-orange w-10 flex items-centerms text-center"></p>
                                        <FilteredSlider/>
                                    </div>
                                </div>
                            );
                        default:
                            return null;
                    }
                })}
            </div>

            {
                showEmailModal &&
                <Modal
                    keepMounted
                    open={showEmailModal}>
                    <StyledBox>
                        <Box>
                            <div className="bg-[#005662] text-white rounded-tl-xl rounded-tr-xl">
                                <div className="p-2">
                                    <div className="text-center">
                                        <h3 className={"mt-3 mb-3 font-archivo font-bold"}>Enter Your Email</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <Formik
                                    initialValues={setupInfo}
                                    validationSchema={emailSchema}
                                    onSubmit={handleEmailSubmit}>
                                    {({handleSubmit}) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="py-4">
                                                <Field
                                                    name="email"
                                                    className="inputStyleDark"
                                                    type="text"
                                                    placeholder="Email"
                                                />
                                                <ErrorMessage
                                                    name="email"
                                                    component="div"
                                                    className="error"
                                                />
                                                {emailError && (
                                                    <div className="text-red-500 text-sm px-3">
                                                        {emailError}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex flex-col justify-center items-center">
                                                <button
                                                    className="bg-primary-dark-green text-white rounded-full py-2 w-1/2"
                                                    type="submit">
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </Box>
                    </StyledBox>
                </Modal>
            }
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} open={showSnackbar} autoHideDuration={6000}
                      onClose={handleClose}>
                <Alert onClose={handleClose} severity="info" sx={{width: '100%'}}>
                    Email Updated Successfully !
                </Alert>
            </Snackbar>
            <Footer/>
            {isLoading && <LoaderComp/>}
            {
                showModal &&
                <Modal
                    keepMounted
                    open={showModal}>
                    <Box sx={style}>
                        <div style={{background : '#005662' , color:'white' , borderTopLeftRadius:'10px' , borderTopRightRadius:'10px'}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 text-center ">
                                        <h3 className={"mt-3 mb-3"}>Set-Up Profile</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <Formik
                                initialValues={setupInfo}
                                validationSchema={setupProfileSchema}
                                onSubmit={(values) => {
                                    setupProfile(values)
                                }}>
                                {({ handleSubmit ,values , setValues}) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row justify-content-center mt-3">
                                            <div className={"col-md-10"}>
                                                <Field
                                                    name="first_name"
                                                    className="inputStyleDark"
                                                    type="text"
                                                    placeholder="First name"
                                                />
                                                <ErrorMessage
                                                    name="first_name"
                                                    component="div"
                                                    className="error"
                                                />
                                            </div>
                                        </div>
                                        <div className="row justify-content-center  mt-4">
                                            <div className={"col-md-10"}>
                                                <Field
                                                    name="last_name"
                                                    className="inputStyleDark"
                                                    type="text"
                                                    placeholder="Last name"
                                                />
                                                <ErrorMessage
                                                    name="last_name"
                                                    component="div"
                                                    className="error"
                                                />
                                            </div>
                                        </div>
                                        <div className="row justify-content-center  mt-4">
                                            <div className={"col-md-10"}>
                                                <PhoneInput
                                                    name={"phone_number"}
                                                    defaultCountry={"LB"}
                                                    placeholder={"Enter phone number"}
                                                    className={"inputStyleDark"}
                                                    value={setupInfo.phone_number}
                                                    onChange={(phone) => setValues({...values , phone_number:phone})}
                                                />
                                                <ErrorMessage
                                                    name="phone_number"
                                                    component="div"
                                                    className="error"
                                                />
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mt-3">
                                            <div className={"col-md-10"}>
                                                <Field
                                                    name="password"
                                                    className="inputStyleDark"
                                                    type="password"
                                                    placeholder="New Password"
                                                />
                                                <ErrorMessage
                                                    name="password"
                                                    component="div"
                                                    className="error"
                                                />
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mt-3">
                                            <div className={"col-md-10"}>
                                                <Field
                                                    name="password_confirmation"
                                                    className="inputStyleDark"
                                                    type="password"
                                                    placeholder="Confirm New Password"
                                                />
                                                <ErrorMessage
                                                    name="password_confirmation"
                                                    component="div"
                                                    className="error"
                                                />
                                            </div>
                                        </div>

                                        <div className="row justify-content-center mt-2">
                                            <div className="col-md-6">
                                                <button className="buttonStyleDark py-2" type="submit">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>

                    </Box>
                </Modal>
            }
        </>
    )
}
export default Home;
