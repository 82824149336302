import React, {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/lab/Alert";
import {useDispatch} from "react-redux";
import {placeOrder} from "../../../store/MenuBuilder/MenuBuilderAction";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import {Backdrop} from "@mui/material";
import {useNavigate} from "react-router-dom";

const Order = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errorStripe, setErrorStripe] = useState('');
    const [paymentMethod, setPaymentMethod] = useState("card");
    const [orderDetails, setOrderDetails] = useState(null);

    const validationSchema = Yup.object().shape({
        seatNumber: Yup.string().required("Seat number is required"),
        orderNote: Yup.string().required("Order note is required")
    });

    const handleSubmit = async (values, {setSubmitting}) => {
        setIsLoading(true);

        const beachId = localStorage.getItem('bag_beach_id');
        const restaurantId = localStorage.getItem('restaurant_id')

        const orderData = {
            beachId: beachId,
            restaurantId: restaurantId,
            paymentType: paymentMethod,
            seatNumber: values.seatNumber,
            note: values.orderNote,
            items: orderDetails.items.map((item) => ({
                itemId: item.id,
                quantity: item.quantity,
            })),
        };

        try {
            const url = await dispatch(placeOrder(orderData));
            setIsLoading(false);
            setSubmitting(false);

            if (paymentMethod === "card" && url) {
                window.location.href = url;
            } else if (paymentMethod === "cash") {
                navigate("/order-menu-complete");
            }
        }  catch (error) {
            setIsLoading(false);
            setSubmitting(false);
            navigate("/order-menu-error");
        }
    };
    const handleCloseSnackBar = () => {
        setErrorStripe('');
    };

    useEffect(() => {
        const savedOrderDetails = localStorage.getItem('orderDetails');
        if (savedOrderDetails) {
            setOrderDetails(JSON.parse(savedOrderDetails));
        }
    }, []);

    return (
        <div className="grid md:grid-cols-[2fr_1fr] gap-4">
            {
                isLoading &&
                <Backdrop
                    open={true}
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            }
            <Formik
                initialValues={{seatNumber: "", orderNote: ""}}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({isSubmitting}) => (
                    <Form className="">
                        <h2 className="text-xl my-4 pt-8 uppercase text-primary-dark-green font-archivo font-bold">Check
                            out</h2>

                        <div className="mb-6">
                            <h2 className="md:text-white md:bg-primary-green max-md:text-primary-dark-green md:py-4 md:px-3 max-md:text-xl font-bold rounded-lg mb-3 md:shadow-lg">
                                Payment Method
                            </h2>
                            <div className="flex mb-2">
                                <label className="flex font-medium items-center whitespace-nowrap cursor-pointer">
                                    <input
                                        type="radio"
                                        value="card"
                                        checked={paymentMethod === "card"}
                                        onChange={() => setPaymentMethod("card")}
                                        className="hidden peer"
                                    />
                                    <div
                                        className="mr-2 h-4 w-4 rounded-full border-2 border-gray-300 peer-checked:border-orange-500 peer-checked:bg-orange-500"></div>
                                    <p>Credit Card</p>
                                </label>
                            </div>

                            <div className="flex my-4">
                                <label
                                    className="flex text-primary-dark-green font-medium items-center whitespace-nowrap cursor-pointer">
                                    <input
                                        type="radio"
                                        value="cash"
                                        checked={paymentMethod === "cash"}
                                        onChange={() => setPaymentMethod("cash")}
                                        className="hidden peer"
                                    />
                                    <div
                                        className="mr-2 h-4 w-4 rounded-full border-2 border-gray-300 peer-checked:border-orange-500 peer-checked:bg-orange-500"></div>
                                    <p>Cash On Delivery</p>
                                </label>
                            </div>
                        </div>

                        <div className="mb-4 text-primary-dark-green">
                            <label className="block mb-2">Seat Number*</label>
                            <Field
                                type="text"
                                name="seatNumber"
                                placeholder="Seat #1234"
                                className="w-full p-2 border border-gray-300 rounded-lg"
                            />
                            <ErrorMessage name="seatNumber" component="div" className="text-red-500 mt-1"/>
                        </div>

                        <div className="mb-4 text-primary-dark-green">
                            <label className="block mb-2">Order Note*</label>
                            <Field
                                as="textarea"
                                name="orderNote"
                                placeholder="Leave us a note..."
                                className="w-full p-2 border border-gray-300 rounded-lg"
                            />
                            <ErrorMessage name="orderNote" component="div" className="text-red-500 mt-1"/>
                        </div>

                        {errorStripe && (
                            <Snackbar open={true} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                                <Alert onClose={handleCloseSnackBar} severity="error">
                                    {errorStripe}
                                </Alert>
                            </Snackbar>
                        )}
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className={`mt-6  px-4 py-1 bg-primary-dark-green text-white rounded-lg hover:bg-primary-dark-green-/20 transition duration-300 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={isLoading || isSubmitting}
                            >
                                Pay Now
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>

            <div className="shadow-2xl rounded-lg mt-14 p-4 flex flex-col h-full">
                {orderDetails ? (
                    <div className="flex-grow">
                        <h2 className="text-primary-dark-green text-xl font-archivo-bold mb-4">Order Information</h2>
                        {orderDetails.items.map((item, index) => (
                            <div key={index}
                                 className="flex justify-between text-primary-dark-green text-md font-archivo-light my-3">
                                <p>{item.name} x {item.quantity}</p>
                                <p> ${item.price}</p>
                            </div>
                        ))}
                        <p className="text-primary-dark-green mt-8">Total: ${orderDetails.total}</p>
                    </div>
                ) : (
                    <p className="flex-grow">No order details found.</p>
                )}
            </div>
        </div>

    );
};

export default Order;
