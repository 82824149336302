import { useEffect, useState } from "react";
import { dashboardHeader } from "../../../constants/dashboardHeader";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation } from "react-router-dom";
import Logout from "./Logout.jsx";

export const DashboardHeader = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(true);
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [openLogout, setOpenLogout] = useState(false);
    const [activePath, setActivePath] = useState(location.pathname);

    const toggleSideBar = () => {
        setIsOpen(!isOpen);
    };

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth < 768);
            if (window.innerWidth >= 768) {
                setShowMenu(false);
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const sideBarIcon = isOpen ? "../../../../../svgs/dashboard/menu-toggle.svg" : "../../../../../svgs/dashboard/closeMenu.svg ";

    const handleLogout = () => {
        setOpenLogout(true);
    };

    const handleCloseLogout = () => {
        setOpenLogout(false);
    };

    const handleLinkClick = (path) => {
        setActivePath(path);
        if (isMobileScreen) {
            setShowMenu(false);
        }
    };

    return (
        <div className="md:h-screen">
            {isMobileScreen && (
                <div
                    className="flex md:justify-around max-md:justify-between items-center bg-primary-dark-green md:p-4 max-md:px-3 max-md:py-3">
                    {!showMenu ? (
                        <MenuIcon style={{ color: "white" }} onClick={toggleMenu} />
                    ) : (
                        <CloseIcon style={{ color: "white" }} onClick={toggleMenu} />
                    )}
                    <img src="../../../../../white-logo.png" alt="Logo" className="w-20" />
                </div>
            )}
            {(!isMobileScreen || showMenu) && (
                <div
                    className={`bg-primary-dark-green h-full flex flex-col justify-between z-50 relative transition-all duration-300 max-md:absolute overflow-y-auto ${isOpen ? 'w-64' : 'w-32'}`}>
                    {!isMobileScreen && (
                        <>
                            <img
                                src={sideBarIcon}
                                alt="menu-toggle"
                                className="w-10 h-10 absolute -right-3 top-2 cursor-pointer"
                                onClick={toggleSideBar}
                            />
                        </>
                    )}
                    <div className="flex flex-col justify-center items-center">
                        {!isMobileScreen && (
                            isOpen ? (
                                <img src="../../../../../white-logo.png" alt="Logo" className="w-32 my-4" />
                            ) : (
                                <img src="../../../../../white-logo.png" alt="Logo" className="w-24 my-4" />
                            )
                        )}
                        {!isOpen && !isMobileScreen &&
                            <div className="flex flex-col items-center">
                                {dashboardHeader.map((value, index) => (
                                    <Link to={value.path} key={index} onClick={() => handleLinkClick(value.path)}>
                                        <div
                                            className={`flex items-center p-3 hover:bg-gradient-to-b from-primary-green to-primary-green/60 via-[#005662] cursor-pointer rounded-md ${activePath === value.path ? 'bg-gradient-to-b from-primary-green to-primary-green/60 via-[#005662]' : ''}`}>
                                            <span className="mr-2">
                                                <img src={value.image} alt={`${value.title} icon`} className="w-5 h-5" />
                                            </span>
                                        </div>
                                    </Link>
                                ))}
                                <div
                                    className="px-3 py-3 cursor-pointer text-white mx-3 gap-3 bg-gradient-to-b from-primary-green to-primary-green/60 via-transparent shadow-2xl mb-10 mt-3 rounded-lg"
                                onClick={handleLogout} >
                                    <img src="../../../../../svgs/dashboard/logout.svg" alt="Log Out icon"
                                         className="w-5 h-5" />
                                </div>
                            </div>
                        }
                    </div>
                    {isOpen && (
                        <>
                            <div className="flex flex-col text-white space-y-3 flex-grow cursor-pointer pt-4">
                                {dashboardHeader.map((value, index) => (
                                    <Link to={value.path} key={index} onClick={() => handleLinkClick(value.path)}
                                          className={`flex items-center text-white p-2 text-sm hover:bg-gradient-to-b from-primary-green to-primary-green/60 via-[#005662] cursor-pointer rounded-md ${activePath === value.path ? 'bg-gradient-to-b from-primary-green to-primary-green/60 via-[#005662]' : ''}`}>
                                        <span className="mr-2">
                                            <img src={value.image} alt={`${value.title} icon`} className="w-5 h-5" />
                                        </span>
                                        {value.title}
                                    </Link>
                                ))}
                            </div>
                            <div
                                className="flex items-center justify-between px-3 py-2 text-sm mx-3 cursor-pointer text-white gap-3 bg-gradient-to-b from-primary-green to-primary-green/60 via-transparent shadow-2xl mb-10 mt-3 rounded-lg"
                                onClick={handleLogout}
                            >
                                Log Out
                                <img src="../../../../../svgs/dashboard/logout.svg" alt="Log Out icon"
                                     className="w-5 h-5" />
                            </div>
                        </>
                    )}
                </div>
            )}
            <Logout open={openLogout} handleClose={handleCloseLogout} />
        </div>
    );
};

export default DashboardHeader;
