import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    reservations: [],
    reservationType: 'walkIn',
    errorMessage: null,
};

export const reservationsSlice = createSlice({
    name: 'reservations',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setReservations: (state, action) => {
            state.reservations = action.payload;
        },
        updateReservationStatus: (state, action) => {
            const { id, status } = action.payload;
            const reservation = state.reservations.find(res => res.id === id);
            if (reservation) {
                reservation.status = status;
            }
        },
        setReservationType: (state, action) => {
            state.reservationType = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        }
    },
});

export const {
    setLoading,
    setReservations,
    updateReservationStatus,
    setReservationType,
    setErrorMessage
} = reservationsSlice.actions;

export default reservationsSlice.reducer;
