import Header from "../../components/Header";
import React, {useEffect, useState} from "react";
import './style.css'
import {useDispatch, useSelector} from "react-redux";
import BagContent from "./BagContent";
import {getBagInformation, getBeachesInBag, getClientSecret} from "../../store/Auth/AuthActions.js";
import LoaderComp from "../../components/LoaderComp";
import PaymentPage from "./PaymentPage";
import Banner from "../../components/Banner";
import {homePageBanner, smallPageBanner} from "../../constants/homeData";
import SmallBanner from "../../components/SmallBanner";
import DefaultSlider from "../../components/DefaultSlider";
import Footer from "../../components/Footer";

const MyBag = () => {
    const {isLoading, beachesInBag, bagInformation} = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState(1)
    const [selectedBeach, setSelectedBeach] = useState()
    const [payAtDoor, setPayAtDoor] = useState(0);
    const [paymentType , setPaymentType] = useState("")
    const [breadCrumb, setBreadCrumb] = useState(
        [
            {
                step: 1,
                label: "Bag Review"
            },
            {
                step: 2,
                label: "Payment"
            },
            {
                step: 3,
                label: "Receipt"
            }
        ]
    )
    useEffect(() => {
        dispatch(getBeachesInBag())
            .then((response) => {
                const selectedBeach = response[0]?.id;
                setSelectedBeach(selectedBeach);
                if (selectedBeach) {
                    const beach = response.find(beach => beach.id === selectedBeach);
                    setPayAtDoor(beach.pay_at_door);
                    setPaymentType(beach.payment_type)
                }
            });
    }, []);

    useEffect(() => {
        if (selectedBeach) {
            localStorage.setItem('bag_beach_id', selectedBeach)
            dispatch(getBagInformation(selectedBeach))
        }
    }, [selectedBeach]);

    const handleSecondPayment = (data) => {
        dispatch(getClientSecret(selectedBeach, data , paymentType))
            .then((response) => {
                console.log(response)
                if(response && paymentType === 'monty'){
                    window.open(response, '_self');
                }else{
                    setActiveTab(2);
                }
            });
    }
    const screen = () => {
        switch (activeTab) {
            case 1:
                if (bagInformation) {
                    return <BagContent beachSelected={selectedBeach} payNow={handleSecondPayment}
                                       payAtDoor={payAtDoor}/>
                }
            case 2:
                return <PaymentPage/>
            // case 3: return <ReceiptPage/>
        }
    }
    const handleSelectBeach = (event) => {
        setSelectedBeach(event.target.value)
    }
    return (
        <>
            <Header/>
            <SmallBanner data={smallPageBanner}/>
            <div>
                <div className="container">
                    <div className="row">
                        <div>
                            <div className="flex flex-col md:gap-2">
                                <h2 className="text-2xl text-primary-dark-green uppercase pt-8 font-bold">Your Cart</h2>
                                <p className="border-b-2 border-primary-orange w-10 flex items-centerms text-center mb-8"></p>
                            </div>
                        </div>
                        <div className="flex ">
                            {
                                breadCrumb.map((item, index) => (
                                    <div className="flex">
                                        <div className="flex gap-3">
                                            <h2 className={"m-0 md:text-xl" + ((index + 1) <= activeTab ? 'breadcrumb-text-active' : 'breadcrumb-text-disabled')}>{item.label}</h2>
                                        </div>
                                        {
                                            index !== breadCrumb.length - 1 &&
                                            <div className="flex items-center justify-between px-2">
                                                <div
                                                    className={((index + 1) <= activeTab ? 'rounded-point-active' : 'rounded-point-disabled')}></div>
                                            </div>

                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    {
                        (activeTab === 1 && beachesInBag.length > 0) &&
                        <div className="row mt-4">
                            <div className="col-md-4">
                                <select value={selectedBeach} onChange={handleSelectBeach} className={"form-control"}>
                                    <option></option>
                                    {
                                        beachesInBag.map((beach) => (
                                            <option value={beach.id}>{beach.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    }

                </div>
                {
                    screen()
                }
                {
                    isLoading && <LoaderComp/>
                }
            </div>
            <Footer/>
        </>
    )
}
export default MyBag
