import Header from "../../components/Header";
import React, {useEffect} from "react";
import BeachRestaurants from "./components/BeachRestaurants";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getRestaurantsMenu} from "../../store/MenuBuilder/MenuBuilderAction";
import {Backdrop, CircularProgress} from "@mui/material";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const RestaurantsMenu = () => {

    const query = useQuery();
    const beach = query.get('beach') || '';
    const dispatch = useDispatch();

    const restaurants = useSelector((state) => state.menuBuilder.restaurants);
    const isLoading = useSelector((state) => state.menuBuilder.isLoading);

    useEffect(() => {
        if (beach) {
            dispatch(getRestaurantsMenu(beach));
        }
    }, [dispatch, beach]);
    return (
        <div>
            <Header hasBackground={false}/>
            <div
                className="relative bg-no-repeat bg-cover min-h-screen flex flex-col justify-center items-center pt-20"
                style={{backgroundImage: `url(/menu-bg.jpg)`}}
            >
                <div className="container mx-auto py-6">
                    <div className="flex items-center justify-between">
                        <div>
                            <p className="sm:text-xl text-white text-lg font-light uppercase mb-2">
                                Our Restaurants at {beach}
                            </p>
                            <p className="border-b-2 border-primary-orange w-10 mb-4"></p>
                            <p className="text-white font-light mb-6">
                                Pick one of our restaurants, go through their menu, <br/>
                                and choose the meal you'd like to order.
                            </p>
                        </div>
                        <div className="flex items-center bg-primary-orange text-white px-3 py-1 rounded-full">
                            <img src="/svgs/call-waiter.svg"/>
                            <button type="button" className="text-sm">Call a Waiter</button>
                        </div>
                    </div>
                    {isLoading ? (
                        <Backdrop
                            open={true}
                            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                    ) : (
                        <BeachRestaurants restaurants={restaurants || []} />
                    )}
                </div>
            </div>
        </div>
    )
}
export default RestaurantsMenu;
