import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    restaurants: [],
    categories: [],
    items: [],
    itemCreationData: {
        categories: [],
        ingredients: []
    },
    isActiveMenu:false,
    activeMenu: null,
    isLoading: false,
    error: null,
};
export const menuBuilderSlice = createSlice({
    name: 'menuBuilder',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setRestaurants: (state, action) => {
            state.restaurants = action.payload;
        },
        addRestaurant: (state, action) => {
            state.restaurants.push(action.payload);
        },
        removeRestaurant: (state, action) => {
            state.restaurants = state.restaurants.filter(
                (restaurant) => restaurant.id !== action.payload
            );
        },
        addCategory: (state, action) => {
            state.categories.push(action.payload);
        },
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
        removeCategory: (state, action) => {
            state.categories = state.categories.filter(
                (category) => category.id !== action.payload
            );
        },
        setItems: (state, action) => {
            state.items = action.payload;
        },
        addItem: (state, action) => {
            state.items.push(action.payload);
        },
        removeItem: (state, action) => {
            state.items = state.items.filter(
                (item) => item.id !== action.payload
            );
        },
        setItemCreationData: (state, action) => {
            state.itemCreationData.categories = action.payload.categories;
            state.itemCreationData.ingredients = action.payload.ingredients;
        },
        setActiveMenu: (state, action) => {
            state.isActiveMenu = action.payload;
        },
        activateMenu: (state, action) => {
            state.activeMenu = action.payload;
        },
    }
})
export default menuBuilderSlice.reducer
