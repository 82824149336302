import PaymentPage from "../../MyBag/PaymentPage";
import EmailIcon from "@mui/icons-material/Email";
import React, {useEffect, useState} from "react";
import {changeGuestEmail} from "../../../store/Auth/AuthActions.js";
import {useDispatch} from "react-redux";

const GuestStripe = ({handleNotYourEmail}) => {
    const dispatch = useDispatch()

    const [email , setEmail] = useState('')
    const [isGuest , setIsGuest]=useState(false)

    useEffect(() => {
        setIsGuest(sessionStorage.getItem('is_guest') === 'true')
        const email = localStorage.getItem('email');
        setEmail(email);
    }, []);

    const handleGuestChangeEmail = () =>{
        dispatch(changeGuestEmail())
            .then(()=>{
                handleNotYourEmail()
            })
    }
    const handleLogout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('email')
        handleNotYourEmail()
    }
    return (
        <div className={"py-3"}>
            <div className="flex items-center text-primary-orange font-bold gap-1 py-8">
                <EmailIcon/>
                Logged In as: {email}
            </div>
            {
                isGuest ?
                <div>
                    <u onClick={handleGuestChangeEmail} className={"text-primary-orange cursor-pointer"}>Change Email?</u>
                </div>
                    :
                    <div>
                        <u onClick={handleLogout} className={"text-primary-orange cursor-pointer"}>Log Out?</u>
                    </div>
            }
            <PaymentPage/>
        </div>
    )
}
export default GuestStripe
