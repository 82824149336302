import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Dropdown} from "../common/Dropdown";
import {Avatar, Divider, Drawer, Menu, MenuItem} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    getCountries,
    globalSearchBeaches,
    handleSearchChange,
    searchBeaches,
    setSelectedCountry, toggleBagSideBar
} from "../../store/Home/HomeActions";
import SettingsIcon from "@mui/icons-material/Settings";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import LogoutIcon from "@mui/icons-material/Logout";
import {signOutUser} from "../../store/Auth/AuthActions.js";
import {useCountries} from "../../pages/Home/hooks";
import './header.css'
import BagSideBar from "../BagSideBar";
import PhoneIcon from '@mui/icons-material/Phone';
const Header = ({hasBackground}) => {
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        search,
        countries,
        selectedCountry,
        showSideBar
    } = useSelector((state) => state.home)

    const {
        token,
        firstName,
        lastName,
    } = useSelector((state) => state.auth)
    const handleCountryChange = (value) => {
        dispatch(setSelectedCountry(value))
    }
    const navigateToMyCard = () => {
        if (token) {
            navigate('/my-bag')
        } else {
            navigate('/login-user')
        }

    }
    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDropdownClose = () => {
        setAnchorEl(null);
    };
    const handleDropdownOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const navigateToMyAccount = () => {
        navigate('/my-account')
    }
    const navigateToMyTickets = () => {
        navigate('/my-tickets')
    }
    const handleLogout = () => {
        dispatch(signOutUser())
        navigate('/')
    }
    const changeSearchValue = (event) => {
        dispatch(handleSearchChange(event.target.value))
    }
    const redirectToGoogleMaps = () => {
        navigate('/google-maps')
    }
    const handleSearch = () => {
        dispatch(globalSearchBeaches(search))
            .then((response) => {
                navigate('/searched-beaches')
            })
    }
    const navigateToLogin = () => {
        navigate('/login-user')
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }
    const {} = useCountries()

    const handleContactUs = () => {
        navigate('/contactUs')
    }
    return <header className="w-full absolute top-0 z-40 min-h-3">
        <div className="left-0 right-0 top-0 z-50 text-white">
            <div className="w-full relative">
                {isMobileScreen ? (
                    <div>
                        <div className="flex items-center justify-between px-4 cursor-pointer">
                            <button type="button" onClick={handleMenuToggle}>
                                <svg width="20" height="20" viewBox="0 0 99 69" fill="none"
                                     xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M93.5 64L5 64M93.5 34.5L5 34.5M93.5 5L5 5" stroke="white" strokeWidth="10"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                            <Link to="/" className="flex items-center gap-3">
                                <img src="/logo-text.svg" className="w-40"/>
                            </Link>
                        </div>
                        <div
                            className={`fixed inset-0 bg-gray-800 bg-opacity-75 text-white z-50 w-72 ${isMenuOpen ? "translate-x-0" : "-translate-x-full"}`}>
                            <div className="bg-[#005662] z-50 h-full flex flex-col">
                                <button type="button" onClick={handleMenuToggle}
                                        className="text-left px-3 pt-2 cursor-pointer">
                                    <svg width="20" height="20" viewBox="0 0 74 74" fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M72.4409 64.9688C73.4335 65.9614 73.9912 67.3077 73.9912 68.7115C73.9912 70.1152 73.4335 71.4615 72.4409 72.4541C71.4483 73.4468 70.102 74.0044 68.6982 74.0044C67.2945 74.0044 65.9482 73.4468 64.9556 72.4541L37 44.4898L9.03561 72.4453C8.043 73.438 6.69672 73.9956 5.29294 73.9956C3.88917 73.9956 2.54289 73.438 1.55027 72.4453C0.557648 71.4527 1.47912e-08 70.1064 0 68.7027C-1.47912e-08 67.2989 0.557648 65.9526 1.55027 64.96L29.5147 37.0044L1.55907 9.04002C0.566453 8.0474 0.00880648 6.70112 0.00880649 5.29734C0.00880651 3.89357 0.566453 2.54729 1.55907 1.55467C2.55169 0.562051 3.89797 0.00440242 5.30175 0.00440241C6.70552 0.00440239 8.0518 0.562051 9.04442 1.55467L37 29.5191L64.9644 1.55027C65.957 0.557647 67.3033 -2.33869e-08 68.7071 0C70.1108 2.33869e-08 71.4571 0.557647 72.4497 1.55027C73.4423 2.54288 74 3.88917 74 5.29294C74 6.69672 73.4423 8.043 72.4497 9.03562L44.4853 37.0044L72.4409 64.9688Z"
                                            fill="white"/>
                                    </svg>
                                </button>
                                <nav className="p-3 text-lg flex flex-col gap-8">
                                    <div className="relative text-sm flex items-center justify-between text-white">
                                        <div className="relative flex items-center justify-between text-white">
                                            <button onClick={handleSearch} className="absolute left-0 mt-2 ml-2">
                                                <img src="/svgs/search-icon.svg" alt="Search Icon" className="w-6 h-6"/>
                                            </button>
                                            <input value={search} onChange={changeSearchValue} onKeyDown={handleKeyDown}
                                                   placeholder="Search Beaches..."
                                                   className="py-2.5 px-10 rounded-full bg-black/40 border-none focus:outline-none"/>
                                        </div>
                                    </div>
                                    <nav>
                                        <div className="flex items-center gap-4 mb-4">
                                            <Avatar onClick={navigateToMyCard}
                                                    sx={{bgcolor: 'transparent', color: '#005662', cursor: 'pointer'}}>
                                                <img src="/svgs/cart.svg" className="w-6 h-6"/>
                                            </Avatar>
                                            {
                                                !token ?
                                                    <Avatar className={"logged-in-avatar"} onClick={navigateToLogin}
                                                            sx={{
                                                                bgcolor: 'transparent',
                                                                height: '30px',
                                                                width: '30px',
                                                                color: '#005662',
                                                                cursor: 'pointer'
                                                            }}>
                                                        <img src="/svgs/profile.svg"/>
                                                    </Avatar>
                                                    :
                                                    <>
                                                        {
                                                            firstName && lastName ?
                                                                <div onClick={handleDropdownOpen}
                                                                     className={"logged-in-avatar me-lg-5 me-md-5 mt-1"}>
                                                                    {firstName.charAt(0)}{lastName.charAt(0)}
                                                                </div>
                                                                :
                                                                <div onClick={handleDropdownOpen}
                                                                     className={"logged-in-avatar me-lg-5 me-md-5 mt-1"}>
                                                                    -
                                                                </div>
                                                        }
                                                        <Menu
                                                            id="basic-menu"
                                                            open={open}
                                                            anchorEl={anchorEl}
                                                            PaperProps={{
                                                                elevation: 0,
                                                                sx: {
                                                                    overflow: 'visible',
                                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                                    mt: 1.5,
                                                                    '& .MuiAvatar-root': {
                                                                        width: 32,
                                                                        height: 32,
                                                                        ml: -0.5,
                                                                        mr: 1,
                                                                    },
                                                                    '&:before': {
                                                                        content: '""',
                                                                        display: 'block',
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        right: 14,
                                                                        width: 10,
                                                                        height: 10,
                                                                        bgcolor: 'background.paper',
                                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                                        zIndex: 0,
                                                                    },
                                                                },
                                                            }}
                                                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}

                                                            onClose={handleDropdownClose}
                                                            MenuListProps={{
                                                                'aria-labelledby': 'filter-button',
                                                            }}>
                                                            <MenuItem onClick={navigateToMyAccount}
                                                                      style={{color: '#005662'}}>
                                                                <SettingsIcon className={"me-2"}/>
                                                                <p className="font-archivo"> My Profile</p>
                                                            </MenuItem>
                                                            <Divider/>
                                                            <MenuItem onClick={navigateToMyTickets}
                                                                      style={{color: '#005662'}}>
                                                                <ConfirmationNumberIcon className={"me-2"}/>
                                                                <p className="font-archivo">My Tickets</p>
                                                            </MenuItem>
                                                            <Divider/>
                                                            <MenuItem onClick={handleLogout} style={{color: '#005662'}}>
                                                                <LogoutIcon className={"me-2"}/>
                                                                <p className="font-archivo">Logout </p></MenuItem>
                                                        </Menu>
                                                    </>
                                            }
                                            <button onClick={redirectToGoogleMaps}
                                                    className="cursor-pointer">
                                                <img src="/svgs/map-icon.svg" className="w-full h-full"/>
                                            </button>
                                        </div>
                                        <Dropdown handleChange={handleCountryChange}
                                                  className={"me-2"}
                                                  value={selectedCountry}
                                                  items={countries} color={'white'}/>
                                    </nav>
                                </nav>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className={" left-0 right-0 top-0 mx-auto z-50 w-full " + (hasBackground ? ' shadow-2xl bg-primary-dark-green' : '')}>
                        <div className="container gap-4 py-2 md:grid-cols-2">
                            <div
                                className="flex cursor-pointer justify-between max-md:grid max-md:grid-cols-3">
                                <div className="flex gap-2">
                                    <button onClick={redirectToGoogleMaps}
                                            className="bg-black/35 rounded-full p-3 cursor-pointer">
                                        <img src="/svgs/map-icon.svg" className="w-4 h-4"/>
                                    </button>
                                    <div className="relative flex items-center justify-between text-white">
                                        <button onClick={handleSearch} className="absolute left-0 top-4 mt-2 ml-2">
                                            <img src="/svgs/search-icon.svg" alt="Search Icon" className="w-6 h-6"/>
                                        </button>
                                        <input value={search} onChange={changeSearchValue} onKeyDown={handleKeyDown}
                                               placeholder="Search Beaches..."
                                               className="py-2.5 px-10 rounded-full bg-black/40 border-none focus:outline-none"/>
                                    </div>
                                </div>
                                <Link to="/" className="flex items-center gap-3">
                                    <img src="/logo-text.svg" className="w-80"/>
                                </Link>
                                <nav className="flex items-center justify-between py-3 text-lg gap-3">
                                    {/*<p className="cursor-pointer">*/}
                                    {/*    <PhoneIcon style={{cursor: 'pointer'}} onClick={handleContactUs}/>*/}
                                    {/*</p>*/}
                                    <Avatar onClick={navigateToMyCard}
                                            sx={{
                                                bgcolor: 'transparent',
                                                color: '#005662',
                                                height: '30px',
                                                width: '40px',
                                                cursor: 'pointer'
                                            }}>
                                        <img src="/svgs/cart.svg"/>
                                    </Avatar>
                                    {
                                        !token ?
                                            <Avatar onClick={navigateToLogin}
                                                    sx={{
                                                        bgcolor: 'transparent',
                                                        height: '30px',
                                                        width: '40px',
                                                        color: '#005662',
                                                        cursor: 'pointer'
                                                    }}>
                                                <img src="/svgs/profile.svg"/>
                                            </Avatar>
                                            :
                                            <>
                                                {
                                                    firstName && lastName ?
                                                        <div onClick={handleDropdownOpen}
                                                             className={"logged-in-avatar mt-1 me-2"}>
                                                            {firstName.charAt(0)}{lastName.charAt(0)}
                                                        </div>
                                                        :
                                                        <div onClick={handleDropdownOpen}
                                                             className={"logged-in-avatar me-lg-5 me-md-5 mt-1"}>
                                                            -
                                                        </div>
                                                }
                                                <Menu
                                                    id="basic-menu"

                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    PaperProps={{
                                                        elevation: 0,
                                                        sx: {
                                                            overflow: 'visible',
                                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                            mt: 1.5,
                                                            me: 2,
                                                            '& .MuiAvatar-root': {
                                                                width: 32,
                                                                height: 32,
                                                                ml: -0.5,
                                                                mr: 1,
                                                            },
                                                            '&:before': {
                                                                content: '""',
                                                                display: 'block',
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 14,
                                                                width: 10,
                                                                height: 10,
                                                                bgcolor: 'background.paper',
                                                                transform: 'translateY(-50%) rotate(45deg)',
                                                                zIndex: 0,
                                                            },
                                                        },
                                                    }}
                                                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}

                                                    onClose={handleDropdownClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'filter-button',
                                                    }}>
                                                    <MenuItem onClick={navigateToMyAccount} style={{color: '#005662'}}>
                                                        <SettingsIcon className={"me-2"}/>
                                                        <p className="font-archivo"> My Profile</p>
                                                    </MenuItem>
                                                    <Divider/>
                                                    <MenuItem onClick={navigateToMyTickets} style={{color: '#005662'}}>
                                                        <ConfirmationNumberIcon className={"me-2"}/>
                                                        <p className="font-archivo">My Tickets</p>
                                                    </MenuItem>
                                                    <Divider/>
                                                    <MenuItem onClick={handleLogout} style={{color: '#005662'}}>
                                                        <LogoutIcon className={"me-2"}/>
                                                        <p className="font-archivo">Logout </p></MenuItem>

                                                </Menu>
                                            </>
                                    }
                                    {/*<select value={selectedCountry}*/}
                                    {/*        onChange={handleCountryChange}*/}
                                    {/*        className="cursor-pointer text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-primary-dark-green/50 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"*/}
                                    {/*        style={{minWidth: '130px'}}>*/}
                                    {/*    {*/}
                                    {/*        countries.map((country, index) => (*/}
                                    {/*            <option key={index} value={country.value}>*/}
                                    {/*                {country.label}</option>*/}
                                    {/*        ))*/}
                                    {/*    }*/}
                                    {/*</select>*/}
                                    <Dropdown handleChange={handleCountryChange} className={"me-2 mt-2"}
                                              value={selectedCountry}
                                              items={countries} color={'white'}/>
                                </nav>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
        <Drawer
            anchor={'right'}
            open={showSideBar}
            onClose={() => dispatch(toggleBagSideBar(false))}
        >
            <BagSideBar/>
        </Drawer>

    </header>;
};

export default Header;
