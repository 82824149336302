import {useDispatch, useSelector} from "react-redux";
import { Elements} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js";
import StripeForm from "../StripeForm";
import LoaderComp from "../../../components/LoaderComp";
import {useEffect} from "react";
import {getBagInformation} from "../../../store/Auth/AuthActions.js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const  PaymentPage = ({}) => {
    const {clientSecret, isLoading} = useSelector((state) => state.auth)
    const dispatch =useDispatch();

    useEffect(() => {
        const selectedBeach = localStorage.getItem('bag_beach_id');
        if (selectedBeach) {
            dispatch(getBagInformation(selectedBeach));
        }
    }, []);
    return (
        <div>
            <div className="container">
                {
                    !isLoading ?
                        <Elements stripe={stripePromise} options={{clientSecret: clientSecret}}>
                            <div className="row">
                                <StripeForm/>
                            </div>
                        </Elements>
                        :
                        <LoaderComp/>
                }
            </div>
        </div>
    )
}
export default PaymentPage
