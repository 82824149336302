import MapBuilderTabs from "./components/MapBuilderTabs.jsx";
import BlockIcon from '@mui/icons-material/Block';

const MapBuilder = () => {
    return (
        <div className="flex flex-col items-center justify-center">
            <img src="../../../../../images/dashboard/coming-soon.png" className="py-3"/>
            <p className="text-2xl text-primary-dark-green font-bold py-2">Coming Soon...</p>
            <p className="text-primary-gray pt-3 text-center">
                This page is currently under construction and will be available soon <br/> Stay tuned for updates !
            </p>
        </div>
        // <div className="container pt-10">
        //     <h2 className="text-primary-dark-green text-2xl font-bold">Map Builder</h2>
        //     <div className="flex items-center justify-between font-archivo-light">
        //         <p className="text-sm text-primary-gray mt-2">Create your map by dragging and dropping
        //             items. <br/> Double-click to auto-arrange</p>
        //         <button type="button" className="flex gap-2 bg-primary-orange/20 text-primary-orange rounded-md px-3 py-2">
        //             <BlockIcon/> Disable Seats
        //         </button>
        //     </div>
        //     <MapBuilderTabs/>
        // </div>
    )
}
export default MapBuilder;
