import CloseIcon from "@mui/icons-material/Close";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import OtpInput from 'react-otp-input';
import {useDispatch, useSelector} from "react-redux";
import {resendEmailOtp, verifyEmailOtp} from "../../store/Auth/AuthActions.js";
import LoaderComp from "../../components/LoaderComp";
import {Snackbar} from "@mui/material";
import {Alert} from "@mui/lab";
import {toggleBagSideBar} from "../../store/Home/HomeActions";
const OtpPage = ()=>{
    const navigate=useNavigate()
    const dispatch = useDispatch()
    const [otp , setOtp]= useState('')
    const [openSnackBar , setOpenSnackBar] = useState(false)
    const[status , setStatus]=useState('')
    const [errorOtp , setErrorOtp] = useState(false)
    const {
        isLoading,
    } = useSelector((state) => state.auth)
    const verifyEmail = () =>{
        dispatch(verifyEmailOtp(otp)).then(()=>{
            let fromPage = localStorage.getItem('route_from')
            if (fromPage && fromPage === "checkout") {
                dispatch(toggleBagSideBar(true))
                navigate('/beach-info')
            } else {
                navigate("/")
            }
        })
        .catch((error)=>{
            setErrorOtp(true)
        })
    }
    const handleCloseSnackBar = () =>{
        setOpenSnackBar(false)
    }

    const navigateBack = () =>{
        navigate(-1)
    }
    const resendOtp = () =>{
        dispatch(resendEmailOtp()).then((res)=>{
            setOpenSnackBar(true)
            setStatus('success')
        }).catch((error)=>{
            setOpenSnackBar(true)
            setStatus('error')
        })

    }
    return (
        <section
            className="background-image"
            style={{ backgroundImage: `url(/main-background.png)` }}
        >
            {
                isLoading && <LoaderComp/>
            }
            <Snackbar anchorOrigin={{vertical:'top' , horizontal:'right'}} open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity={status} sx={{ width: '100%' }}>
                    {status === 'success' ? 'An email has been sent with a new otp': 'An error occurred while resending otp'}
                </Alert>
            </Snackbar>
            <div className="overlay">
                <div className="container">
                    <button className="exit">
                        <CloseIcon onClick={navigateBack}/>
                    </button>
                    <h1 className="my-5">
                        <b>Verify Your Email</b>
                    </h1>
                    <p>An email has been sent to you with an otp.</p>
                    <div className="row justify-content-center mt-3">
                        <div className="col-md-4">
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={5}
                                inputType={"number"}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} className={"form-control"} style={{color:'black' ,fontSize:'18px' ,height:'4rem' , textAlign:'center'}}  />}
                            />
                        </div>
                    </div>

                    <div className="row justify-content-center mt-2">
                        <div className="col-md-12 text-center mt-3">
                            <u onClick={resendOtp} style={{cursor:'pointer'}}>Resend Email</u>
                            <button onClick={verifyEmail} className="buttonStyle px-5 py-2" type="submit">
                                Verify Email
                            </button>
                        </div>
                    </div>
                    {
                        errorOtp && (
                            <div className="row justify-content-center mt-4">
                                <div className="col-md-12">
                                    <div className="alert alert-danger" role="alert">
                                        Incorrect Otp.
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>

            </div>
        </section>
    )
}
export default OtpPage
