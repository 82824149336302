import { setLoading, updateOrderStatus } from './OrdersSlice';
import axios from "axios";

export const changeOrderStatus = (id, status) => async (dispatch) => {
    const beachId = sessionStorage.getItem('beach_id');

    dispatch(setLoading(true));
    try {
        await axios.post(`/beach/${beachId}/update-order/${id}`, { status });
        dispatch(updateOrderStatus({ id, status }));
    } catch (error) {
        console.error('Error updating order status:', error);
    } finally {
        dispatch(setLoading(false));
    }
};
