import {ImageList, ImageListItem} from "@mui/material";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

export const BeachImageGallery = () =>{
    const [ isMobileScreen ,setIsMobileScreen] = useState(false)
    const { selectedBeach } = useSelector((state) => state.home)
    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return(
       <div className="container-fluid">
           <div className="ms-lg-5 ms-md-5 me-lg-5 me-md-5">
               {
                   isMobileScreen ?
                       <div className={"row"}>
                           {
                               selectedBeach.gallery?.map((item)=>(
                                   <div className="col-md-12 mt-2">
                                       <img src={item.image} style={{height:'200px' , width:'100%'}} />
                                   </div>
                               ))
                           }
                       </div>
                       :
                       <ImageList
                           sx={{ width: '100%', height: '100%' }}
                           variant="quilted"
                           cols={12}
                           rowHeight={121}>
                           {selectedBeach.gallery?.map((item) => (
                               <ImageListItem  key={item.image} cols={item.cols || 1} rows={item.rows || 1}>
                                   <img
                                       style={{borderRadius:'20px'}}
                                       {...srcset(item.image, 121, item.rows, item.cols)}
                                       loading="lazy"
                                   />
                               </ImageListItem>
                           ))}
                       </ImageList>
               }

           </div>
       </div>
    )
}
export default BeachImageGallery
