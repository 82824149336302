import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    orders: [],
    errorMessage: null,
};

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setOrders: (state, action) => {
            state.orders = action.payload;
        },
        updateOrderStatus: (state, action) => {
            const { id, status } = action.payload;
            const order = state.orders.find(order => order.id === id);
            if (order) {
                order.status = status;
            }
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
    },
});

export const {
    setLoading,
    setOrders,
    updateOrderStatus,
    setErrorMessage,
} = ordersSlice.actions;

export default ordersSlice.reducer;
