import {createSlice} from "@reduxjs/toolkit";
import moment from "moment/moment";

const initialState = {
    selectedDate:sessionStorage.getItem('current_selected_date') ?? moment().format('YYYY-MM-DD'),
    changeDate:false,
    map:[],
    country:{},
    initialMap:[],
    reservations:[],
    isLoading:false,
    mapScreenWidth: 1920,
    mapScreenHeight: 970,
    itemBackground:[],
    beach:{},
    stats:{},
    itemInformation:{},
    showItemModal:false,
    addNewReservationModal:false,
    isSwapping:false,
    addNewCustomReservation:false,
    itemToSwap:{},
    userBeachInfo:{},
    criterias:[],
    reportInfo:{},
    openMapModal:false,
    addingWalkin:false,
    openSettingsModal:false,
    openAddSettingsModal:false,
    isLoadingRes:false,
    sideFilters:{},
    showSwappingMessage: false,
    orders:[],
    waitersCalled:[]
}


export const beachAdminSlice = createSlice({
    name:'beachAdmin',
    initialState,
    reducers:{
        setIsLoading(state, action){
            state.isLoading = action.payload
        },
        setOpenMapModal(state,action){
            state.openMapModal = action.payload
        },
        setAddingWalking(state , action){
           state.addingWalkin = action.payload
        },
        setReservations(state , action){
            state.reservations = action.payload
        },
        setSelectedDate(state,action){
            state.selectedDate = action.payload
        },
        setChangeDate(state,action){
            state.changeDate = action.payload
        },
        setMap(state, action){
            state.map = action.payload
        },
        setCountry(state , action){
          state.country = action.payload
        },
        setMapScreenWidth(state, action){
            state.mapScreenWidth = action.payload
        },
        setMapScreenHeight(state, action){
            state.mapScreenHeight = action.payload
        },
        setBeach(state,action){
          state.beach = action.payload
        },
        setStats(state,action){
            state.stats = action.payload
        },
        setInitialMap(state , action){
            state.initialMap = action.payload
        },
        setItemBackground ( state , action){
            let itemBackgroundObject = {}
            action.payload.section_categories.map((item)=>{
                itemBackgroundObject[item.code] = item.background_image
            })
            state.itemBackground = itemBackgroundObject
        },
        setItemInformation(state, action){
            state.itemInformation = action.payload
        },
        setShowItemModal(state, action){
            state.showItemModal = action.payload
        },
        setAddNewReservationModal(state , action){
            state.addNewReservationModal = action.payload
        },
        setOpenSettingsModal(state , action){
          state.openSettingsModal = action.payload
        },
        setOpenAddSettingModal(state , action){
          state.openAddSettingsModal = action.payload
        },
        handleAddCustomReservation(state,action){
            state.addNewCustomReservation = action.payload
        },
        setUsers(state , action){
            state.users = action.payload
        },
        setIsSwapping(state, action){
            state.isSwapping = action.payload
        },
        setItemToSwap(state, action){
            state.itemToSwap = action.payload
        },
        setUserBeachInfo(state , action){
            state.userBeachInfo = action.payload
        },
        setCriterias(state,action){
            state.criterias = action.payload
        },
        setFilter(state,action){
            state.sideFilters = action.payload
        },
        setReportInfo(state , action){
            state.reportInfo = action.payload
        },
        setIsLoadingAddingReservation(state , action){
            state.isLoadingRes = action.payload
        },
        setShowSwappingMessage(state, action) {
            state.showSwappingMessage = action.payload;
        },
        setOrders(state, action) {
            state.orders = action.payload;
        },
        setWaitersCalled(state, action) {
            state.waitersCalled = action.payload;
        },
    }
})
export const { setShowSwappingMessage } = beachAdminSlice.actions;
export default beachAdminSlice.reducer;
