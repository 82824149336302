import CloseIcon from "@mui/icons-material/Close";
import {useNavigate} from "react-router-dom";
import OtpInput from "react-otp-input";
import React, {useState} from "react";
import {checkOtp, resendEmailOtp, verifyEmailOtp} from "../../../store/Auth/AuthActions.js";
import {toggleBagSideBar} from "../../../store/Home/HomeActions";
import {useDispatch} from "react-redux";
import {Alert} from "@mui/lab";
import {Snackbar} from "@mui/material";
import * as yup from "yup";

const CheckOtp = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [otp, setOtp] = useState('')
    const [errorOtp , setErrorOtp] = useState(false)
    const [openSnackBar , setOpenSnackBar] = useState(false)
    const[status , setStatus]=useState('')
    const verifyOtp = () => {
        dispatch(checkOtp(otp)).then(() => {
            navigate('/createNewPassword')
        })
            .catch((error) => {
                setErrorOtp(true)
            })
    }
    const resendOtp = () =>{
        dispatch(resendEmailOtp()).then((res)=>{
            setOpenSnackBar(true)
            setStatus('success')
        }).catch((error)=>{
            setOpenSnackBar(true)
            setStatus('error')
        })

    }
    function goBack() {
        navigate(-1)
    }

    return (
        <>
            <section
                className="background-image"
                style={{backgroundImage: `url(/main-background.png)`}}>
                <div className="overlay">
                    <div className="container">
                        <button className="exit mb-5" onClick={goBack}>
                            <CloseIcon/>
                        </button>
                        <h1 className="my-5 text-3xl">
                           OTP
                        </h1>
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-4">
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={5}
                                    inputType={"number"}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} className={"form-control"} style={{
                                        color: 'black',
                                        fontSize: '18px',
                                        height: '5rem',
                                        textAlign: 'center'
                                    }}/>}
                                />
                            </div>
                            {/*<p>*/}
                            {/*    <div className="col-md-12 text-center mt-3">*/}
                            {/*        <u onClick={resendOtp} style={{cursor: 'pointer'}}>Resend Otp</u>*/}
                            {/*    </div>*/}
                            {/*</p>*/}
                        </div>
                        <button
                            className="font-bold flex justify-content-end bg-white text-primary-dark-green px-4 py-1 mt-4 cursor-pointer rounded-full"
                            onClick={verifyOtp}>
                            Enter OTP
                        </button>
                    </div>
                </div>
            </section>
            <Snackbar anchorOrigin={{vertical:'top' , horizontal:'right'}} open={errorOtp} autoHideDuration={6000} onClose={()=>setErrorOtp(false)}>
                <Alert onClose={()=>setErrorOtp(false)} severity={'error'} sx={{ width: '100%' }}>
                   Incorrect OTP!
                </Alert>
            </Snackbar>
        </>
    )
}
export default CheckOtp;
