import Order from "./Order";
import Header from "../../../components/Header";
import React from "react";

const OrderCheckout = () => {
    return (
        <>
            <div>
                <Header hasBackground={false}/>
                <div
                    className="relative bg-no-repeat bg-cover h-[64vh] pt-32 pb-10 flex justify-center"
                    style={{backgroundImage: `url(/menu-bg-1.png)`}}
                >
                </div>
            </div>
            <div className="md:container max-md:mx-5">
                <div>
                    <Order/>
                </div>
            </div>
        </>

    )
}
export default OrderCheckout;
