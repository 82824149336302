import {useDispatch, useSelector} from "react-redux";
import {
    addNewReservation, handleSelectedDateChange,
    modifyMap,
    openMapModalAction, setAddingWalkinAction,
    setLoading,
    setMap, submitAddWalkin
} from "../../../../store/BeachAdmin/BeachAdminActions";
import {Avatar, Box, Modal} from "@mui/material";
import DisabledAdminSection from "../DisabledAdminSection";
import React, {useEffect, useRef, useState} from "react";
import ModalMapItem from "./ModalMapItem";
import AddNewReservationCard from "../AddNewReservationCard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment/moment";
import AddNewReservationModal from "../AddNewReservationModal/AddNewReservationModal";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Space} from "react-zoomable-ui";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height:'90%',
    backgroundColor: 'white',
    boxShadow: 24,
    borderRadius: '15px',
    outline: 0
};

const customTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    fontFamily:'archivo-regular',
                    '& .MuiInputBase-root': {
                        color: 'white',
                        backgroundColor: '#005F95',
                        borderRadius:'50px',
                        '& .MuiInputBase-input': {
                            color: 'white',
                            backgroundColor: '#005F95',
                            borderRadius:'50px',
                            fontFamily:'archivo-regular',
                        },
                        '& .MuiSvgIcon-root': {
                            color: 'white',
                        },
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'white',
                        },
                    },
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#005F95',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#005F95',
                        },
                    },
                },
            },
        },
    },
});

const MapModal = () =>{
    const today = new Date().toISOString().split('T')[0];
    const {isSwapping, mapScreenWidth, mapScreenHeight,openMapModal, map,selectedDate , initialMap , addingWalkin} = useSelector((state) => state.beachAdmin)
    const dispatch = useDispatch()
    const [selectedItems , setSelectedItems]= useState([])
    const [numberOfPeople , setNumberOfPeople] = useState()
    const [date, setDate] = useState(dayjs());
    const handleClose = () => {
        const updatedInitialMap = map.map((mapItem, i) =>
            mapItem.is_selected_to_add  ? { ...mapItem, is_selected_to_add: false } : mapItem
        );
        dispatch(modifyMap(updatedInitialMap))
        dispatch(openMapModalAction(false))
    }
    useEffect(() => {
        let data = map?.filter((item)=>item.is_selected_to_add === true)
        setSelectedItems(data)
    }, [map]);
    const handleResize = () => {
        dispatch(setLoading(true));
        let initialSize = mapScreenWidth ;
        let currentMapSize = window.innerWidth * 0.9;
        let currentSize = currentMapSize * (10/12);
        let ratio = initialSize / currentSize;
        let currentMapSizeHeight = window.innerHeight * 0.9;
        let heightRatio = mapScreenHeight / currentMapSizeHeight;
        let data = initialMap?.map(section => ({
            ...section,
            startX: section.startX / ratio,
            endX: section.endX / ratio,
            startY: section.startY / heightRatio,
            endY: section.endY / heightRatio,
            height:  (section.special_height ?? 50)  / heightRatio + 'px',
            width:  (section.special_width ?? 50)  / heightRatio + 'px'
        }));
        dispatch(setMap(data))
        dispatch(setLoading(false));
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [initialMap]);

    useEffect(() => {
        handleResize()
    }, [initialMap])

    const addWalkin = () => {
        let data = map?.filter((item)=>item.is_selected_to_add === true)
        dispatch(submitAddWalkin(data , selectedDate))
        dispatch(openMapModalAction(false))
        dispatch(setAddingWalkinAction(true))
    }
    const handleDateChange = (newValue) => {
        setDate(newValue);
        dispatch(handleSelectedDateChange(newValue.format('YYYY-MM-DD')));
    };
    // const handleDateChanged = (event) =>{
    //     setDate(event.target.value)
    //     dispatch(handleSelectedDateChange(event.target.value))
    // }
    const initViewPort = async (vp) => {
        vp.setBounds({x: [0, mapScreenWidth], y: [0, mapScreenHeight]});
        vp.camera.centerFitAreaIntoView({
            left: 0,
            top: 0,
            width: mapScreenWidth,
            height: mapScreenHeight
        });
        // vp.containerHeight = 300
    };
    const spaceRef = useRef(null);

    return (
        <Modal
            open={openMapModal}
            onClose={handleClose}>
            <Box style={style}>
                <div className={"container-fluid h-100"}>

                    <div className="row h-100">
                        <div className="col-md-10 h-100">
                            <div className={"h-100"}>
                                <Space
                                    onCreate={initViewPort}
                                    ref={spaceRef}
                                    style={{
                                        height: '100%',
                                        overflowY: "scroll",
                                        overflowX: "scroll",
                                        border: '1px solid black',
                                        position: 'relative',
                                    }}>
                                    {
                                        map?.map((section, index) => (
                                            section.type === 'ITEM' ?
                                                <ModalMapItem numberOfPeopleAllowed={numberOfPeople} selectedItemsNumber={selectedItems}  key={'item_disabled_modal_' + index} index={index}
                                                              item={section}/>
                                                :
                                                <DisabledAdminSection key={'section_disabled_dragged_' + index}
                                                                      section={section} index={index}/>
                                        ))
                                    }
                                </Space>
                            </div>
                        </div>
                        <div className="col-md-2" style={{borderLeft:'2px solid #005662' , height:'100%' , overflowY:'scroll'}}>
                            <div className="row ">
                                <div className="col-md-12 mt-1">
                                    <div className="float-end">
                                        <CloseIcon onClick={handleClose} style={{cursor:'pointer'}}/>
                                    </div>
                                </div>
                            </div>
                            {
                                addingWalkin ?
                                    <div className="row justify-content-center h-100">
                                        <div className="col-md-10">
                                            <h3 className={"mt-3 text-primary-blue text-xl font-bold"}>Add a Walk-in</h3>
                                        </div>
                                        <div className="col-md-10 text-center">
                                            {
                                                selectedItems.length > 0 ?
                                                    <button onClick={addWalkin} className={"btn btn-primary bg-primary-blue"}>Add Seats</button>
                                                    :
                                                    <button className={"btn btn-primary bg-primary-blue"} disabled>Add Seats</button>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className="container">
                                        <div className="row h-100">
                                            <div className="col-md-10">
                                                <h3 className={"mt-3 text-primary-blue text-xl font-bold"}>Add a Reservation</h3>
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <label className={"text-primary-blue ont-bold"}>Reservation Date</label>

                                                {/*<input onChange={handleDateChanged}*/}
                                                {/*       min={today} placeholder={"Date"}*/}
                                                {/*       value={date} type={"date"}*/}
                                                {/*       className={"mt-2 border-2 border-primary-blue px-2 py-1 rounded-full"} />*/}
                                                <ThemeProvider theme={customTheme}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            value={date}
                                                            views={['year', 'month', 'day']}
                                                            onChange={handleDateChange}
                                                            renderInput={(params) => <TextField {...params} />}
                                                            minDate={dayjs().startOf('day')}
                                                            inputFormat="YYYY-MM-DD"
                                                            className={"mt-2 border-2 border-primary-blue rounded-full"}
                                                        />
                                                    </LocalizationProvider>
                                                </ThemeProvider>
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <label className={"text-primary-blue font-bold"}>Number of people</label>
                                                <input onChange={(e)=>setNumberOfPeople(e.target.value)}
                                                       placeholder={"Number Of People"}
                                                       value={numberOfPeople} type={"number"}
                                                       className={"mt-2 border-2 border-primary-blue px-2 py-1 rounded-full"} />
                                            </div>
                                            <div>
                                                <label className={"text-primary-blue font-bold mt-3"}>Reservation Info</label>
                                                <AddNewReservationCard numberOfPeople={numberOfPeople}/>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
export default MapModal
