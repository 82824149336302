import {guestSlice} from "./GuestSlice";
import axios from "axios";
import {setLoading,setErrorMessage} from '../Dashboard/Reservations/ReservationsSlice.js';

export const setGuestBagAction = (data) => {
    return (dispatch)=>{
        dispatch(guestSlice.actions.setBag(data))
    }
}
export const setGuestUniqueBag = (data) => {
    return (dispatch)=>{
        dispatch(guestSlice.actions.setGuestUniqueBag(data))
    }
}
export const setGuestOriginalItems = (data) => {
    return (dispatch)=>{
        dispatch(guestSlice.actions.setOriginalItems(data))
    }
}

export const setGuestTotalValues = (subTotal , total) => {
    return (dispatch)=>{
        dispatch(guestSlice.actions.setSubTotal(total))
        dispatch(guestSlice.actions.setTotal(subTotal))
    }
}
export const getGuestBagInformation = (beachId) => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/bag-information/beach/' + beachId)
            let data = response.data.data
            data.map((bag) => {
                let names = []
                for (let i = 0; i < bag.count - 1; i++) {
                    names.push({
                        name: 'Guest ' + (i + 1)
                    })
                }
                bag.names = names
            })
            dispatch(guestSlice.actions.setGuestUniqueBag(data))
        } catch (e) {
            throw e;
        } finally {

        }
    }
}

export const addToGuestCard = (data , card) => {
    return async (dispatch) => {
        try {
            const response = await axios.post('/beach/' + localStorage.getItem('selected_beach') + '/add-to-guest-bag', {data: data , unique:card})
        } catch (e) {
            console.log(e)
        }
    }
}

// export const exportExcelGuests = (date: string, filters: any) => async (dispatch: AppDispatch) => {
//     dispatch(setLoading(true));
//     try {
//         const params: any = { date };
//
//         if (filters.reservation_status) {
//             params.reservation_status = filters.reservation_status;
//         }
//         if (filters.reservation_types) {
//             params.reservation_types =filters.reservation_types;
//         }
//         const beachId = sessionStorage.getItem('beach_id');
//
//         const response = await axios.post(`beach/${beachId}/export-guests`, {
//             ...params,
//         } , {
//             responseType: 'blob' // Set the response type to blob for file download
//         });
//         if (response && response.data) {
//             const url = window.URL.createObjectURL(new Blob([response.data]));
//
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', 'report.xlsx');
//             document.body.appendChild(link);
//             link.click();
//
//             // Clean up
//             document.body.removeChild(link);
//             window.URL.revokeObjectURL(url);
//         }
//     } catch (error) {
//         dispatch(setErrorMessage('Error export excel'));
//     } finally {
//         dispatch(setLoading(false));
//     }
// };
