import React, {useEffect, useState} from "react";
import {Box, Modal, Select, MenuItem, FormControl, Chip} from "@mui/material";
import ImageUpload from "../ImageUpload";
import {
    addNewCategory,
    addNewItem,
    getCategories, getItemCreationData,
    getItems
} from "../../../../../../store/MenuBuilder/MenuBuilderAction";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import OutlinedInput from "@mui/material/OutlinedInput";
import * as Yup from "yup";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
};

const getValidationSchema = (type, selectedRow) => {
    if (type === 'item') {
        return Yup.object({
            itemName: Yup.string().required('Item name is required'),
            category: Yup.string().required('Category is required'),
            price: Yup.number().required('Price is required').positive('Price must be positive'),
            description: Yup.string().required('Description is required'),
            itemImage: Yup.mixed().test(
                'required',
                'Image is required',
                value => !!value || (selectedRow && selectedRow.image)
            ),
        });
    } else if (type === 'category') {
        return Yup.object({
            categoryName: Yup.string().required('Category name is required'),
            categoryImage: Yup.mixed().test(
                'required',
                'Image is required',
                value => !!value || (selectedRow && selectedRow.image)
            ),
        });
    }
};
const ItemCategoryModal = ({
                               isOpen,
                               onClose,
                               type,
                               imagePreview,
                               handleImageChange,
                               handleRemoveImage,
                               itemName,
                               restaurantId,
                               modalType,
                               selectedRow
                           }) => {
    const [price, setPrice] = useState('');
    const [category, setCategory] = useState('');
    const [ingredients, setIngredients] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [description, setDescription] = useState(false);

    const {categories, ingredients: availableIngredients} = useSelector((state) => state.menuBuilder.itemCreationData);

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            itemName: itemName || '',
            category: category || '',
            price: price || '',
            ingredients: ingredients || [],
            itemImage:null,
            isActive: isActive !== undefined ? isActive : false,
            categoryName: modalType === 'edit' && selectedRow ? selectedRow.name : '',
            categoryImage: null,
            description: description || '',
        },
        enableReinitialize: true,
        validationSchema: getValidationSchema(type, selectedRow),
        onSubmit: (values) => {
            handleAddNew(values);
        },
    });
    // const handleAddNew = async (values) => {
    //     const beachId = sessionStorage.getItem('beach_id');
    //     const formData = new FormData();
    //
    //     if (modalType === 'edit') {
    //         formData.append('_method', 'put');
    //     }
    //
    //     if (type === 'item') {
    //         formData.append('name', values.itemName);
    //         formData.append('price', values.price);
    //         formData.append('category_id', values.category);
    //         formData.append('is_active', isActive ? 1 : 0);
    //         formData.append('description', values.description);
    //
    //         values.ingredients.forEach((ingredient, index) => {
    //             formData.append(`ingredients[${index}]`, ingredient);
    //         });
    //
    //         if (values.categoryImage && typeof values.categoryImage === 'object') {
    //             formData.append('image', values.categoryImage);
    //         } else if (selectedRow && selectedRow.image) {
    //             formData.append('image', selectedRow.image);
    //         }
    //
    //         if (modalType === 'edit') {
    //             await dispatch(addNewItem(beachId, restaurantId, formData, selectedRow.id));
    //         } else {
    //             await dispatch(addNewItem(beachId, restaurantId, formData));
    //         }
    //
    //         await dispatch(getItems(beachId, restaurantId));
    //
    //     } else if (type === 'category') {
    //         formData.append('name', values.categoryName);
    //
    //         if (values.categoryImage && typeof values.categoryImage === 'object') {
    //             formData.append('image', values.categoryImage);
    //         } else if (selectedRow && selectedRow.image) {
    //             formData.append('image', selectedRow.image);
    //         }
    //         if (modalType === 'edit') {
    //             await dispatch(addNewCategory(beachId, restaurantId, formData, selectedRow.id));
    //         } else {
    //             await dispatch(addNewCategory(beachId, restaurantId, formData));
    //         }
    //
    //         await dispatch(getCategories(beachId, restaurantId));
    //     }
    //
    //     onClose();
    // };

    const handleAddNew = async (values) => {
        const beachId = sessionStorage.getItem('beach_id');
        const formData = new FormData();

        if (modalType === 'edit') {
            formData.append('_method', 'put');
        }

        if (type === 'item') {
            formData.append('name', values.itemName);
            formData.append('price', values.price);
            formData.append('category_id', values.category);
            formData.append('is_active', values.isActive ? 1 : 0);
            formData.append('description', values.description);

            values.ingredients.forEach((ingredient, index) => {
                formData.append(`ingredients[${index}]`, ingredient);
            });

            if (values.itemImage && typeof values.itemImage === 'object') {
                formData.append('image', values.itemImage);
            } else if (selectedRow && selectedRow.image) {
                formData.append('image', selectedRow.image);
            }

            if (modalType === 'edit') {
                await dispatch(addNewItem(beachId, restaurantId, formData, selectedRow.id));
            } else {
                await dispatch(addNewItem(beachId, restaurantId, formData));
            }
            await dispatch(getItems(beachId, restaurantId));

        } else if (type === 'category') {
            formData.append('name', values.categoryName);

            if (values.categoryImage && typeof values.categoryImage === 'object') {
                formData.append('image', values.categoryImage);
            } else if (selectedRow && selectedRow.image) {
                formData.append('image', selectedRow.image);
            }

            if (modalType === 'edit') {
                await dispatch(addNewCategory(beachId, restaurantId, formData, selectedRow.id));
            } else {
                await dispatch(addNewCategory(beachId, restaurantId, formData));
            }

            await dispatch(getCategories(beachId, restaurantId));
        }

        onClose();
    };

    useEffect(() => {
        if (isOpen) {
            const beachId = sessionStorage.getItem('beach_id');
            if (restaurantId && beachId) {
                dispatch(getItemCreationData(beachId, restaurantId));
            }
        }
    }, [isOpen, restaurantId, dispatch]);


    useEffect(() => {
        if (isOpen && selectedRow) {
            formik.setValues({
                itemName: selectedRow.name || '',
                category: selectedRow.category_id || '',
                price: selectedRow.price || '',
                ingredients: selectedRow.ingredients ? selectedRow.ingredients.map(ing => ing.id) : [],
                description: selectedRow.description || '',
                isActive: selectedRow.is_active === 1,
                categoryName: type === 'category' ? selectedRow.name || '' : '',
            });
        }
    }, [isOpen, selectedRow]);

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box sx={style}>
                <button
                    onClick={onClose}
                    className="absolute top-1 right-2 text-gray-600 hover:text-gray-900 mx-2"
                >
                    <span className="text-lg">x</span>
                </button>
                <h2 className="text-lg font-bold text-primary-dark-green text-center">
                    {modalType === 'edit' ? `Edit ${type === 'item' ? 'Item' : 'Category'}` : `Add New ${type === 'item' ? 'Item' : 'Category'}`}
                </h2>
                <p className="text-primary-gray text-sm py-4 text-center">
                    Please include the details of the new {type} you want to add.
                </p>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit(e);
                }}>
                    <div className="grid gap-4">
                        {type === 'item' && (
                            <>
                                <div className="grid grid-cols-2 gap-3">
                                    <div>
                                        <p className="text-primary-dark-green text-sm">Item Name</p>
                                        <input
                                            type="text"
                                            name="itemName"
                                            value={formik.values.itemName}
                                            onChange={formik.handleChange}
                                            placeholder="Item Name"
                                            className="w-full p-2 border border-primary-gray mt-2 font-medium rounded text-sm"
                                        />
                                        {formik.touched.itemName && formik.errors.itemName ? (
                                            <p className="text-red-600 text-sm">{formik.errors.itemName}</p>
                                        ) : null}
                                    </div>
                                    <div>
                                        <p className="text-primary-dark-green text-sm">Category</p>
                                        <FormControl fullWidth margin="dense">
                                            <Select
                                                sx={{height: 42}}
                                                name="category"
                                                value={formik.values.category}
                                                onChange={(e) => formik.setFieldValue('category', e.target.value)}
                                            >
                                                {categories.map((cat) => (
                                                    <MenuItem key={cat.id} value={cat.id}>
                                                        {cat.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {formik.touched.category && formik.errors.category ? (
                                                <p className="text-red-600 text-sm">{formik.errors.category}</p>
                                            ) : null}
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-3">
                                    <div>
                                        <ImageUpload
                                            imagePreview={imagePreview}
                                            onImageChange={(e) => {
                                                handleImageChange(e);
                                                formik.setFieldValue('itemImage', e.target.files[0]);
                                            }}
                                            onRemoveImage={handleRemoveImage}
                                        />
                                        {formik.touched.itemImage && formik.errors.itemImage ? (
                                            <p className="text-red-600 text-sm">{formik.errors.itemImage}</p>
                                        ) : null}
                                    </div>
                                    <div>
                                        <p className="text-primary-dark-green text-sm mb-2">Ingredients</p>
                                        <FormControl fullWidth margin="dense">
                                            <Select
                                                multiple
                                                value={formik.values.ingredients}
                                                onChange={(e) => formik.setFieldValue('ingredients', e.target.value)}
                                                input={<OutlinedInput id="select-multiple-chip"/>}
                                                renderValue={(selected) => (
                                                    <div>
                                                        {selected.map((value) => (
                                                            <Chip key={value}
                                                                  label={availableIngredients.find(ing => ing.id === value)?.name}/>
                                                        ))}
                                                    </div>
                                                )}
                                                sx={{height: 42}}
                                                variant="outlined"
                                            >
                                                {availableIngredients.map((ingredient) => (
                                                    <MenuItem key={ingredient.id} value={ingredient.id}>
                                                        {ingredient.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div>
                                    <p className="text-primary-dark-green text-sm">Price</p>
                                    <input
                                        type="number"
                                        name="price"
                                        value={formik.values.price}
                                        onChange={formik.handleChange}
                                        placeholder="Enter Price"
                                        className="w-full p-2 border border-primary-gray font-medium rounded text-sm"
                                    />
                                    {formik.touched.price && formik.errors.price ? (
                                        <p className="text-red-600 text-sm">{formik.errors.price}</p>
                                    ) : null}
                                </div>
                                <div>
                                    <p className="text-primary-dark-green text-sm">Description</p>
                                    <textarea
                                        name="description"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                        placeholder="Enter description"
                                        rows={3}
                                        className="w-full p-2 border border-primary-gray font-medium rounded text-sm"
                                    />
                                    {formik.touched.description && formik.errors.description ? (
                                        <p className="text-red-600 text-sm">{formik.errors.description}</p>
                                    ) : null}
                                </div>
                            </>
                        )}

                        {type === 'category' && (
                            <>
                                <div>
                                    <p className="text-primary-dark-green text-sm">Category Name</p>
                                    <input
                                        type="text"
                                        name="categoryName"
                                        value={formik.values.categoryName}
                                        onChange={formik.handleChange}
                                        placeholder="Category Name"
                                        className="w-full p-2 border border-primary-gray font-medium rounded text-sm"
                                    />
                                    {formik.touched.categoryName && formik.errors.categoryName ? (
                                        <p className="text-red-600 text-sm">{formik.errors.categoryName}</p>
                                    ) : null}
                                </div>
                                <ImageUpload
                                    imagePreview={imagePreview}
                                    onImageChange={(e) => {
                                        handleImageChange(e);
                                        formik.setFieldValue('categoryImage', e.target.files[0]);
                                    }}
                                    onRemoveImage={handleRemoveImage}
                                />
                                {formik.touched.categoryImage && formik.errors.categoryImage ? (
                                    <p className="text-red-600 text-sm">{formik.errors.categoryImage}</p>
                                ) : null}
                            </>
                        )}
                    </div>

                    <div className="flex justify-end mt-1 gap-2">
                        <button
                            type="submit"
                            className="w-full bg-primary-dark-green text-white text-center rounded-md py-2"
                        >
                            {modalType === 'edit' ? 'Save' : 'Add New'}
                        </button>
                    </div>
                </form>
            </Box>
        </Modal>
    );
};

export default ItemCategoryModal;
